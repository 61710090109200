import React from 'react';



export default class ProductContent extends React.Component {
    constructor(props) { super(props); this.state = { ContentElements: [], loading: true }; }

    componentDidMount() {
        this.createContent()
    }
    fetchContent = async () => {
        return await fetch('content.json',{method: 'GET'})
        .then(response => response.json())
        .then(response => response.productPageContent )
        .catch( async (err) => {
            console.log(err) 
        })
    }

    async createContent(){
        var contentList = this.props.content.productPageContent || await this.fetchContent()
        var elems = [], index=0;
        contentList.forEach( content => {
            elems.push(
                this.elementConstruction( content, index )
                
            )
            index++;
        })
     
        this.setState({ loading: false, ContentElements: elems })

    }

    elementConstruction( content, index ){
        return(
            <div className="callout callout-box" key={index+'_key'}>

                <h3 aria-label={content.title} className='marketing-site-three-up-title'>{ content.title }</h3>
                <h4 aria-label={content.subtitle} className='marketing-site-three-up-title'>{ content.subtitle }</h4>
                <br/>
                <p className="marketing-site-three-up-desc">{ content.description_text }</p> 

            </div>
        )
    }
    render() { 
        return(
            <div style={{width: '100%', height: 'auto', display: 'flex', flexDirection: 'column' , justifyContent: 'center', alignItems: 'center'}}>
            { !true ? this.state.ContentElements : <></>}
            </div>
        ) 
    }
}