
const domain_endings = '.ac .ag .at .be .bg .bs .cc .ch .cn .co .cz .de .dk .es .eu .fi .fm .fr .gg .gr .gs .hr .hu .im .in .io .is .it .jp .li .lt .lv .ly .me .nl .no .pl .pt .pw .ro .ru .se .sh .sk .tk .to .tv .uk .us .yt'.split(' ')
const domain_endings_1 = '.co.at .co.gg .co .uk .com .de .com .mt .com .pt .com .tr .de .com .net .gg .or .at .org .gg .org .pt .dev'.split(' ')

export default async function PreviewLink( domain ){
    const current = '.'+domain.split('.').slice(-1);
    const special_domains = await fetch('/endungen.json')
    .then(msg => msg.json())
    .then( msg => msg.domains )
    .catch(err => { console.warn(err); return [] } )
    
    var domain_endings_test = domain_endings.includes(current ) || domain_endings_1.includes(current) || special_domains.includes(current)
    if( domain_endings_test ){
        return await fetch( "https://api.peekalink.io/", 
        {
            method: 'POST',
            headers:{"X-API-Key": "b241c843-0908-4669-872a-904ce244e20d",'Content-Type': 'application/json'},
            body:JSON.stringify({'link': domain})
        })
        .then(  (response) => {
            if(response.status === 500) return true; 
            else if ((response.status >= 400 && response.status < 500) || (response.status >= 200 && response.status < 300) )
            {
                return false
            }
        })
        .catch( (error) => { return true; })    
    
    }else{  return undefined  }    
} 