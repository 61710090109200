import React from 'react';
import './NewsPage.css';
import './custom.scss';
import NewsCardComponent from '../NewsPage/Components/NewsCard/NewsCard';
import FullScreenLoader from '../../LoadingScreen/fullscreen/FullScreenLoader';
import ErrorPage from '../../Error/ErrorPage'
import ComponentImportHandler from '../../import/ImportHandler';
import ApiRequest from '../../../Middleware/apiRequest';
import UID from '../../../Middleware/CreateUID'
import {IterateChildren} from './Components/NewsCard/Scripts/Iterate';

export default class NewsPage extends React.Component {
    constructor(props) {
        super(props);
        document.querySelectorAll('title')[0].textContent='Nachrichten | Calypso Software'
        window.scrollTo(0, 0)
        this.state = { loading: true, elements: [], failed: false, serachResult: null }
        this.news = []      
        this.results = []
        this.handleSearch = this.handleSearch.bind(this)
        this.im = new ComponentImportHandler(); this.global = this.stcoks = this.mixedArray = undefined; this.first = true;
        this.globalNews = new ApiRequest({
            url: 'https://calypso-software.eu-4.evennode.com/api/news/global', 
            method: 'GET', 
            token: localStorage.getItem('user_token'), 
            api: 'global'
        })
        this.stockNews = new ApiRequest({
            url: 'https://calypso-software.eu-4.evennode.com/api/news/stocks', 
            method: 'GET', 
            token: localStorage.getItem('user_token'), 
            api: 'stcoks'
        })
        this.crawledContent = new ApiRequest({
            url: 'https://calypso-software.eu-4.evennode.com/api/news/realtime-news',
            method: 'GET',
            token: localStorage.getItem('user_token'),
            api: 'news'
        })
        this.crawledSeries = new ApiRequest({
            url: 'https://calypso-software.eu-4.evennode.com/api/news/realtime-news/serien',
            method: 'GET',
            token: localStorage.getItem('user_token'),
            api: 'news'
        })
        this.crawledPanorma = new ApiRequest({
            url: 'https://calypso-software.eu-4.evennode.com/api/news/realtime-news/panorama',
            method: 'GET',
            token: localStorage.getItem('user_token'),
            api: 'news'
        })
        this.crawledPolitik = new ApiRequest({
            url: 'https://calypso-software.eu-4.evennode.com/api/news/realtime-news/politik',
            method: 'GET',
            token: localStorage.getItem('user_token'),
            api: 'news'
        })
        this.crawledStars = new ApiRequest({
            url: 'https://calypso-software.eu-4.evennode.com/api/news/realtime-news/stars',
            method: 'GET',
            token: localStorage.getItem('user_token'),
            api: 'news'
        })
        this.crawledKino = new ApiRequest({
            url: 'https://calypso-software.eu-4.evennode.com/api/news/realtime-news/kino',
            method: 'GET',
            token: localStorage.getItem('user_token'),
            api: 'news'
        })
        this.germanNews = new ApiRequest({
            url: 'https://calypso-software.eu-4.evennode.com/api/news/realtime-news/germany',
            method: 'GET',
            token: localStorage.getItem('user_token'),
            api: 'news'
        })

    }
    checkResponse = (response) => {

        if(!Array.isArray(response)){
            if(!!response.message){
                return response.message
            }
        }else{  
            return response
        }
    }

    async componentDidMount() {
        
        
        if( this.first ){

            
            this.first = false
            let arr = [
                this.global = await this.globalNews.GetNews(),
                this.stcoks = await this.stockNews.GetNews(),
                this.crawled = await this.crawledContent.GetNews(),
                this.crawledSerien = await this.crawledSeries.GetNews(),
                this.crawledPanorma = await this.crawledPanorma.GetNews(),
                this.crawledPolitik = await this.crawledPolitik.GetNews(),
                this.crawledStars = await this.crawledStars.GetNews(),
                this.crawledKino = await this.crawledKino.GetNews(),
                this._germanNews = await this.germanNews.GetNews()
            ]
            arr.forEach( (el) => { if(typeof el === "boolean"){
                this.setState({failed: true})
                return
            }})

            var filter_1 = [], filter_2 = [], filter_3 = [], filter_4 = [], filter_5 = [], filter_6 =[], filter_7 = [], filter_8 = [], filter_9 = []


            filter_1 = this.checkResponse(this.global); 
            filter_2 = this.checkResponse(this.stcoks); 
            filter_3 = this.checkResponse(this.crawled); 
            filter_4 = this.checkResponse(this.crawledSerien);
            filter_5 = this.checkResponse(this.crawledPanorma); 
            filter_6 = this.checkResponse(this.crawledPolitik)
            filter_7 = this.checkResponse(this.crawledStars)
            filter_8 = this.checkResponse(this.crawledKino)
            filter_9 = this.checkResponse(this._germanNews)

            
            this.setState({loading: false,  elements: this.createNewsPage(this.mixedContent( filter_1.concat(filter_2).concat(filter_3).concat(filter_4).concat(filter_5).concat(filter_6).concat(filter_7).concat(filter_8).concat(filter_9)  ))})
        }
        
    }
    test=()=>{        
        let results = []
        if(this.state.elements.length === 0 ){ return }        
        else {
           results = IterateChildren({children: this.state.elements, searchString: 'Bonnie'})
           if(results.length > 0 ){
           } 
        }
    }

    createNewsPage = data => {
        var elems = [];
        this.news = data.map( obj => obj )
        try{
            this.news.forEach( news => {
                try{
                if( news.pubDate !== 'undefined undefined' ){ 
                    
                                
                    elems.push(
                        <NewsCardComponent 
                            articleSrc={ news.url || news.link || ''} 
                            title={ news.title } 
                            author={news.author || news.source.name }  
                            text={ news.description || '' }
                            pictureSrc={news.urlToImage || ''} 
                            source={ news.source ||  news.source.name || ''}
                            __id={`${UID()}-news-index`}
                            publishedAt={ news.publishedAt|| news.pubDate }
                            key={UID()}
                            __key={UID()}
                        />
                    )
                }
                }catch(e){
                    
                }
            })
            return elems;
        }catch(e){
            return <FullScreenLoader />;
        }
    }
    mixedContent = ( arr ) => {
        let currentIndex = arr.length, randomIndex;
        while(currentIndex !== 0){ 
            randomIndex = Math.floor( Math.random() * currentIndex )
            currentIndex--;
            [arr[currentIndex], arr[randomIndex] ] = [arr[randomIndex], arr[currentIndex]]             
        }
        return arr
    }
    handleSearch = serchedString => {      
        this.results = []
        if(this.state.elements.length === 0 ){ return }        
        else {
            this.results = IterateChildren({children: this.state.elements, searchString: serchedString}) 
            if(this.results.length > 0 ){
                this.setState({ serachResult: this.results })
            }else{
                this.setState({ serachResult: this.results })
            }
        }
    }
    render() {
        return (
            <>
            { new ComponentImportHandler().NavMenu() }
            { new ComponentImportHandler().Logo() }
            { this.state.loading ? <></> : 
               
                <SearchBar serchedString={ this.handleSearch } results={ this.state.serachResult }/>  }
            {

                this.state.loading && !this.state.failed ? <FullScreenLoader /> 
                
                : this.state.failed ? <ErrorPage textContent={'Leider ist dieser Service vorübergehend nicht verfügabr auf dieser Seite'}/> : <> 
                                    
                
                {/*
                    <div className="news-page-headline"               
                style={{marginTop: "50px", marginBottom: '50px', position: 'relative', top: '0', left: '0', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: 'auto', textAlign: 'center', color: 'white' }}>
                    <div>
                        <br/>
                        <h1 aria-label="Echtzeit Nachrichten" style={{paddingTop: '50px', fontFamily: 'var(--font-family)', fontSize: window.innerWidth > 768 ? '2rem' : '1.5rem'}}> Aktuelle Nachrichten </h1>
                    </div>                  
                </div>
                */}
                
                 <div className="news-page-main-container">

                    
                    
                    { 
                    this.state.failed ? <ErrorPage /> :

                    <div className="Content-Container-Row">
                            {this.state.elements}
                        </div>
                                            
                    }
                    {!this.state.loading ? <></> : <></> }
                    </div>
                    
               
                <br/>
                <br/>
                {new ComponentImportHandler().Footer()}
                </>
            
            }
            
            {new ComponentImportHandler().MenuComp()}
            
            </>
        )
    }
}


class SearchBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = { open: false, serachResultElems: [] }
        this.searchString = ''
        this.received = false;
        this.handleChange = this.handleChange.bind(this);
        this.handleSearchClick = this.handleSearchClick.bind(this);
        
    }

    componentDidUpdate(){
        if(this.received){ return }
        if( !this.state.open || !this.props.results ){ return }
        else{

            this.received = true;
            this.createSearch()
        }
        
    }
    handleClick = () => {
        if( !this.state.open ){
            document.body.classList.add('stop-scrolling')
        }else{
            document.body.classList.remove('stop-scrolling')
        }
        this.setState({open: !this.state.open, serachResultElems: [] })
    }
    handleChange = e => {
        e.preventDefault();
        this.searchString = e.target.value
    }
    handleSearch = () => {
        if( this.searchString.trim().length >= 4 ){
            this.received = false
            this.props.serchedString(this.searchString)    
        }        
    }
    handleSearchClick = id => {
        document.body.classList.remove('stop-scrolling')
        this.setState({open: false, serachResultElems: [] })
        document.getElementById(id).scrollIntoView()
    }
    createSearch = () => {
        let elems = [];
        this.props.results.forEach( result => {
            
            elems.push(
                <SearchResultCon 
                key={UID()}
                title={result.props.title}                
                author={result.props.author}
                __id = { result.props.__id }
                handleSearchClick={this.handleSearchClick}
                />
            )
            
        })
        if( elems.length > 0 ){
            this.setState({serachResultElems: elems})
        }else{
            this.setState({serachResultElems: this.noResultFound() })
        }
    }
    noResultFound = () => {
        return(
            <div className="search-result-box">
                <h7>Leider konnten keine Artikel gefunden werden</h7>
                <hr/>
                <p>Prüfen sie gegebenfalls den eingegebenen Begriff</p>
            </div>
        )
    }
    render()
    {
        return (
            <React.Fragment>

            <div className="Search-Icon-Con" onClick={this.handleClick} >
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                width="50.000000pt" height="38.000000pt" viewBox="0 0 50.000000 50.000000"
                preserveAspectRatio="xMidYMid meet">

                <g transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)"
                fill="#fff" stroke="none">
                <path d="M4 377 c-2 -7 -3 -69 -2 -138 l3 -124 245 0 245 0 0 135 0 135 -243
                3 c-194 2 -244 0 -248 -11z m476 -127 l0 -120 -230 0 -230 0 0 120 0 120 230
                0 230 0 0 -120z"/>
                <path d="M307 302 c-35 -38 -5 -97 46 -90 14 1 34 -5 45 -15 11 -10 23 -14 26
                -9 3 5 -3 17 -13 26 -12 11 -16 25 -13 42 10 51 -56 85 -91 46z m67 -24 c8
                -14 7 -21 -6 -34 -30 -30 -75 9 -48 42 16 18 39 15 54 -8z"/>
                </g>
                </svg>
            </div>
            {
                this.state.open 
                ? 
                <div className="search-menu-box-con">
                    <div className="search-bar-input-con">

                        <div className="search-button" onClick={this.handleSearch}>
                        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                            width="40.000000pt" height="40.000000pt" viewBox="0 0 60.000000 60.000000"
                            preserveAspectRatio="xMidYMid meet">

                            <g transform="translate(0.000000,60.000000) scale(0.100000,-0.100000)"
                            fill="#fff" stroke="none">
                            <path d="M92 508 c-17 -17 -17 -399 0 -416 17 -17 399 -17 416 0 17 17 17 399
                            0 416 -17 17 -399 17 -416 0z m269 -104 c34 -32 46 -81 30 -126 -10 -29 -9
                            -34 21 -65 25 -26 29 -36 20 -45 -9 -9 -19 -5 -45 20 -31 30 -36 31 -65 21
                            -46 -16 -94 -4 -128 32 -55 57 -33 157 41 190 35 15 95 3 126 -27z"/>
                            <path d="M225 375 c-50 -49 -15 -135 55 -135 41 0 80 39 80 80 0 41 -39 80
                            -80 80 -19 0 -40 -9 -55 -25z"/>
                            </g>
                        </svg>
                        </div>
                        <div className="search-template-scss">
                            <input type="search" placeholder="Suche Artikel nach Themen..." 
                            onChange={this.handleChange}
                            />                            
                        </div>
                        <div className="search-button" onClick={this.handleClick}>
                        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                            width="40.000000pt" height="40.000000pt" viewBox="0 0 64.000000 64.000000"
                            preserveAspectRatio="xMidYMid meet">

                            <g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
                            fill="#fff" stroke="none">
                            <path d="M240 547 c-49 -16 -133 -102 -148 -153 -28 -94 -8 -169 63 -239 101
                            -102 229 -102 330 0 98 97 101 222 8 325 -65 72 -158 97 -253 67z m190 -61
                            c59 -39 85 -89 85 -166 0 -78 -26 -127 -88 -168 -56 -37 -153 -39 -210 -3 -76
                            47 -111 140 -88 229 14 51 75 117 123 131 53 16 135 6 178 -23z"/>
                            <path d="M234 399 c-3 -6 9 -26 27 -45 l33 -34 -34 -35 c-29 -30 -32 -37 -20
                            -45 11 -7 24 -1 47 22 l33 32 33 -32 c23 -23 36 -29 47 -22 12 8 9 15 -20 45
                            l-34 35 34 35 c26 27 31 38 22 47 -9 9 -20 4 -47 -22 l-35 -34 -33 32 c-36 35
                            -42 37 -53 21z"/>
                            </g>
                        </svg>
                        </div>
                    </div>
                   
                    <div className="search-result-con">                      
                        {
                            this.state.serachResultElems
                        }
                    </div>
                </div>


                : <></>
            }
            </React.Fragment>
        )
    }
}


class SearchResultCon extends React.Component {

    handleClick = () => {
        this.props.handleSearchClick(this.props.__id)
    }
    render()
    {
        return(
            <div className="search-result-box" onClick={ this.handleClick }>
                <h7>{this.props.title}</h7>
                <hr/>
                <p>{this.props.author}</p>
            </div>
        )
    }

}