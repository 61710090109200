import React from 'react';
import './SCSS/Styles.scss';
import DescriptionContent from './DiscriptionComp/DescripContent';
import ComponentImportHandler from '../../../import/ImportHandler';


export default class DescriptionComp extends React.Component {
    constructor(props) {
        super(props);
        this.state = { elements: [], loading: true }
        this.ImportHandler = new ComponentImportHandler();
    }
    async componentDidMount(){        
        this.renderContent( this.props.content )
    }
    renderContent = (content) => {
        content = content.products;
        var elements = [], index=0;
        content.forEach( product => {
            elements.push(
                <DescriptionContent 
                    imageSrc={product.imageSrc}
                    title={product.title}
                    sizes={product.sizes}
                    text={product.text}
                    key={`product_${index}`}
                />
            )
            index++;
        })
        this.setState({ elements: elements, loading: false })
    }
    render() {
        return (
            <section>
                <h2 style={{textAlign: 'center', fontSize: '2rem', zIndex: '20'}}>
                    
                    Services
                    
                </h2>
            <div aria-label="Product-Description-2" className="row product-container" 
            style={{border: "none !important", paddingTop: '100px' }}>
                <div className="large-12 columns">
                    <div className="row __content" >
                        {
                            this.state.loading ? <></> 
                            :
                            this.state.elements
                        }
                    </div>
                </div>
            </div>  
            </section>
        
        )
    }
}