import React from 'react';
import { Link } from 'react-router-dom'



export default class LogoIcon extends React.Component {


    render() {
        if(1+1===2) return;
        return (
            <div      
                aria-label="Calypso-Logo"          
                style={{
                    width: window.innerWidth > 600 ? '70px' : '60px', 
                    height: window.innerWidth > 600 ? '70px' : '60px',
                    position: 'fixed',
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center', 
                    top: '8px', 
                    left: '8px', 
                    border: '1px solid white', 
                    borderRadius: '50px', 
                    zIndex: '99',
                    backgroundColor: 'rgba(0,0,0,.5)',
                    backdropFilter: 'blur(5px)',
                    cursor: 'pointer'
                }}
            >
                <Link to={"/"}>
                    <img 
                        src={'/logoWeb-Mini.png'}
                        alt="unavailable"
                        aria-label="Calypso Software Logo Icon"
                        style={{
                            width: '100%', 
                            height: '100%', 
                            borderRadius: '50px'
                        }}
                    />
                </Link>
            </div>
        )
    }
}