import React from 'react';
import './FullLoader.scss';
import MenuComp from '../../MenuComp/MenuComp'


export default class FullScreenLoader extends React.Component {
    render(){
        return(
            <section style={{zIndex: !this.props.zIndex ? '' : this.props.zIndex }}>
              <MenuComp />  
                <div className="content">
                    <div className="loading">
                    <p>loading</p>
                <span></span>
                </div>
            </div>
            </section>
        ) 
    }
}