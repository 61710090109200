import React from 'react';
import './Styles.scss'

export default class DescriptionContent extends React.Component {
    render(){
        return(
            <div className="large-3 small-6 columns individual" 
            style={{border: "none !important", marginBottom: "150px"}}
            >
                <div className="inner-con">

                <h6 className="panel" >{this.props.title}</h6>
                <br />
                <p className="text-left">{this.props.text}</p>
                </div>
            </div>    
        ) 
    }
}  