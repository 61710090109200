import './MenuComp.css';
import './MenuComp2.scss';
import React from 'react';    
import { Link } from 'react-router-dom';

export default class MenuComp extends React.Component{

    render(){
        return(
        <div className="about" aria-label="Social-Media-Links">
            <Link aria-label="Link-Kontaktformular" to="/contact" className="bg_links social portfolio">
                <svg fill="#ffffff" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 30 30" width="30px" height="30px">    
                <path d="M 15 3 C 7.82 3 2 7.925 2 14 C 2 17.368 3.7933281 20.378484 6.6113281 22.396484 C 6.6832805 23.932468 6.4452784 26.053382 4.3261719 27.03125 A 0.5 0.5 0 0 0 4.3222656 27.033203 A 0.5 0.5 0 0 0 4 27.5 A 0.5 0.5 0 0 0 4.5 28 C 4.5119372 28 4.5232366 27.998109 4.5351562 27.998047 A 0.5 0.5 0 0 0 4.5429688 27.998047 C 6.9769949 27.982445 9.0432734 26.667034 10.46875 25.277344 C 10.92075 24.836344 11.550875 24.619328 12.171875 24.736328 C 13.081875 24.909328 14.028 25 15 25 C 22.18 25 28 20.075 28 14 C 28 7.925 22.18 3 15 3 z"/></svg>                
            </Link>
            <a aria-label="Link-GitHub" className="bg_links social dribbble" href="https://github.com/calipsow" target="_blank"  rel="noreferrer">
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                    width="40.000000pt" height="40.000000pt" viewBox="0 0 90.000000 90.000000"
                    preserveAspectRatio="xMidYMid meet">
                    <g transform="translate(0.000000,90.000000) scale(0.100000,-0.100000)"
                    fill="#fff" stroke="none">
                    <path d="M355 796 c-107 -34 -186 -102 -232 -201 -24 -51 -28 -73 -28 -145 0
                    -72 4 -94 28 -145 43 -92 115 -158 212 -195 24 -9 25 -8 25 30 0 39 -1 40 -33
                    40 -42 0 -57 13 -90 72 -30 55 -22 60 26 18 47 -40 93 -40 125 1 22 27 22 29
                    5 35 -64 19 -108 42 -125 65 -29 39 -34 99 -12 145 13 26 18 55 16 86 -5 60
                    13 70 72 42 55 -27 194 -26 248 1 54 27 70 16 67 -47 -2 -36 3 -64 15 -87 22
                    -41 17 -102 -12 -140 -17 -23 -60 -45 -126 -66 -18 -5 -18 -7 5 -31 19 -20 24
                    -38 27 -94 l4 -69 47 24 c63 32 124 98 158 170 24 51 28 73 28 145 0 72 -4 94
                    -28 145 -30 65 -81 124 -138 160 -78 50 -199 68 -284 41z"/>
                    </g>
                </svg>              
            </a>
            <a aria-label="Link-Linkedin" className="bg_links social linkedin" href="https://twitter.com/me_Calypso" target="_blank"  rel="noreferrer">                
                <svg fill="#ffffff" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 30 30" width="30px" height="30px">
                <path d="M28,6.937c-0.957,0.425-1.985,0.711-3.064,0.84c1.102-0.66,1.947-1.705,2.345-2.951c-1.03,0.611-2.172,1.055-3.388,1.295 c-0.973-1.037-2.359-1.685-3.893-1.685c-2.946,0-5.334,2.389-5.334,5.334c0,0.418,0.048,0.826,0.138,1.215 c-4.433-0.222-8.363-2.346-10.995-5.574C3.351,6.199,3.088,7.115,3.088,8.094c0,1.85,0.941,3.483,2.372,4.439 c-0.874-0.028-1.697-0.268-2.416-0.667c0,0.023,0,0.044,0,0.067c0,2.585,1.838,4.741,4.279,5.23 c-0.447,0.122-0.919,0.187-1.406,0.187c-0.343,0-0.678-0.034-1.003-0.095c0.679,2.119,2.649,3.662,4.983,3.705 c-1.825,1.431-4.125,2.284-6.625,2.284c-0.43,0-0.855-0.025-1.273-0.075c2.361,1.513,5.164,2.396,8.177,2.396 c9.812,0,15.176-8.128,15.176-15.177c0-0.231-0.005-0.461-0.015-0.69C26.38,8.945,27.285,8.006,28,6.937z"/></svg>                        
            </a>  
            
            <svg className="bg_links" fill="#ffffff" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 30 30" width="40px" height="40px">
                <path d="M 24 3 A 3 3 0 0 0 21 6 A 3 3 0 0 0 21.375 7.4472656 L 15.550781 14 L 8.8261719 14 A 3 3 0 0 0 6 12 A 3 3 0 0 0 3 15 A 3 3 0 0 0 6 18 A 3 3 0 0 0 8.8242188 16 L 15.550781 16 L 21.373047 22.550781 A 3 3 0 0 0 21 24 A 3 3 0 0 0 24 27 A 3 3 0 0 0 27 24 A 3 3 0 0 0 24 21 A 3 3 0 0 0 22.869141 21.222656 L 17.337891 15 L 22.869141 8.7773438 A 3 3 0 0 0 24 9 A 3 3 0 0 0 27 6 A 3 3 0 0 0 24 3 z"/>
            </svg>
            
        </div>
        ) 
    }
}