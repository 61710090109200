import CookieHandler from '../../../Middleware/CookieHandler';

export default class Security{
    constructor(options){
        this.secure = false;
        this.message = '';
        this.CookieHandler = new CookieHandler();
        this.kp = { spublic: null }
    }

    async requestForm( data ){

        if( this.secure && this.checkCookie(null, true) ){
            return await fetch("https://public.herotofu.com/v1/fa93ac10-7c81-11ec-abb7-8162bacc5dd5",{
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify( data )
            }).then( response => {           
                this.formControl(true)
                return false;
                // set loading status 
            }).catch( error => {
                console.error( error );
                return false;
                // set loading status
            })

        } else return [];
    }
    checkCookie(form_control=null, skip_check=false){
            if(skip_check){ return true }
            var sendForm = false;
            var checkExist = false;

            var storedCookies = document.cookie;
            var cookies = storedCookies.split(';')
            cookies.forEach( cookie => {
                var [name, value] = cookie.split('=');
                if( name.trim() === 'form_control' && value.trim() === 'false' )
                {
                    checkExist = true;
                    sendForm = true;

                } else if( name.trim() === 'form_control' ) {  
                    checkExist = true;
                } 
            }) 
            if(form_control != null ) return checkExist;
            else return sendForm;                 
    }


    formControl(form_is_send=null){
        var d = new Date();
        d.setTime(d.getTime() + (24*60*60*1000));
        let expires = "expires="+ d.toUTCString();

        if( form_is_send === null){
            if( !this.checkCookie(true) )
            {
                document.cookie='form_control=false;'+expires+';path=/'
            } else return;
        } else {
            document.cookie='form_control=true;'+expires+';path=/'
        }
    }

    async contactFormSecurity(){
        var currentCookie = this.getStoredCookie()

        if( !currentCookie ) return true;
        this.secure=true; return true;
       
    }
    async sendFormRequest(){
        if( !this.secure ) return false;
        return 200;
    }
    

    getStoredCookie(){
        var currentCookie = this.CookieHandler.parseSessionCookie()
        if( currentCookie.length === 0 ){ this.message = "No Cookies stored in the browser!"; console.log( this.message ); return false }        
        currentCookie = currentCookie.filter( object => object.name === 's' )
        if( currentCookie.length === 0 ) return false;
        else currentCookie = currentCookie[0];

        return currentCookie;
    }

} 