import React from 'react';
import FullScreenLoader from '../LoadingScreen/fullscreen/FullScreenLoader';
import LoadingScreen from '../LoadingScreen/loadingComp';
import NavigationComponent from '../SkillPage/comp/NavMenu';
import MenuComp from '../MenuComp/MenuComp'
import ImpressumMain from '../Impressum/ImpressumMain';
import Footer from '../Footer/Footer';
import ContactComp from '../ContactComponent/ContactComp';
import LogoIcon from '../LogoIcon/LogoIcon';
import BackgroundWaves from '../../AppV2/CompsV2/BackgroundWaves';
export default class ComponentImportHandler extends React.Component {
    
    Footer = () => <Footer />
    Impressum = () => <ImpressumMain /> 
    MenuComp = () => <MenuComp />;
    NavMenu = (options='') => <NavigationComponent relative={!options ? '' : true }/>;
    FullLoader = (zIndex=null) => <FullScreenLoader zIndex={zIndex}/>;
    LoadScreen = (small=undefined) => <LoadingScreen smallLoader={small}/>;
    ContactForm = (viewHeight=false) => <ContactComp viewHeight={viewHeight} />
    Logo = () => <LogoIcon /> 
    Waves = () => <BackgroundWaves />
}