import React from 'react';
import './SCSS/Styles.scss';
import UID from '../../../Middleware/CreateUID';

export default class Content extends React.Component {

    
    splittetText = (text) => {
        var splittetText = text.split('. ')
        var paragraphArr = [];

        const createParagraph = (text) => {
            return(
                
                
                    
                <p style={{fontSize: '1rem', fontFamily: 'var(--font-family)'}} key={UID()}>{text+'. '}</p>
                
            )
        }
        splittetText.forEach( sentence => {
            paragraphArr.push(
                createParagraph( sentence )
            )
        })
    
        return paragraphArr;
        
    }
    
    render()
    {
        return(

            <div className="blog-post content-container_1 left" aria-label="Product-Content" key={UID()} style={{marginBottom: '300px'}}>
                <h3 style={{fontSize: '1.75rem', fontWeight: 'normal', fontFamily: 'var(--font-family)'}}>{this.props.title}
                <hr/>
                </h3>
                
                {!this.props.thumbnail 
                ?  <br /> 
                :  <img aria-label={this.props.title} 
                    srcalt="thumbnail" className="thumbnail" sizes={this.props.sizes} 
                    srcSet={this.props.thumbnail}/>
                
                }
                { 
                    this.splittetText(this.props.text)
                }
                {                
                !this.props.thumbnail ? <br/> :
                    <></>
                }
            </div>
        )
    }
}

