import axios from 'axios'


export default async function Authaticate(options){
    if(options.type === 'user'){
        return await axios.post("https://calypso-software.eu-4.evennode.com/api/auth",{

        }).then(res=>{

            res=res.data;
            return res

        }).catch(err=>{

            return false;
        })
    }else if(options.type === 'admin' && !!options.name && !!options.pass ){
        return await axios.post("https://calypso-software.eu-4.evennode.com/admin/auth",{
            name: options.name, pass: options.pass
        
        }).then(response => {
            response = response.data;          
            return response;

        }).catch(err => {
            console.warn(err.message)
            return false
        })

    }
}