import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

export default function Footer(){
    return(
        <footer id="dk-footer" className="dk-footer">

        <div className="footer_container">
            <div id="__logo" className="footer_row">
                <div style={{width: 'auto', height: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <img 
                aria-label="Calypso Software Logo"
                alt="unavailable"
                src={
                    process.env.NODE_ENV === 'production' 
                    ? "https://calypso-software.com/logoWeb-PhotoRoom.png" 
                    : 'http://localhost:3000/logoWeb-PhotoRoom.png'
                }
                width="190" height="190"
                />
                
                </div>
            </div>

        </div>


        <div className="copyright">
            <div className="footer_container">
                <div className="footer_row1">
                    <div className="col-md-6">
                        <div className="copyright-menu">
                            <ul>

                                <li>
                                    <Link aria-label="Link zum Impressum" to="/impressum">Impressum</Link>
                                </li>
                                <li>
                                    <Link aria-label="Link zur Privacy Policy" to="/datenschutz">Privacy Policy</Link>
                                </li>
                                <li>
                                    <Link aria-label="Link zum Kontaktformular" to="/contact">Hey Skatedeluxe !</Link>
                                </li>

                            </ul>
                            
                        </div>
                    </div>

                </div>

            </div>
        
            
        </div>
        
        
        </footer>

    )
}