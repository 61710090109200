import React from 'react';
import './SCSS/Styles.scss'
import DescriptionComp from './comp/Description'
import ComponentImportHandler from '../../import/ImportHandler';
import ProductContent from './comp/FeatureSection/ContentRenderProducts';
import './comp/FeatureSection/SCSS/Styles.scss';
import SVG from './comp/SVG';

export default class ProductPage extends React.Component {
    constructor(props){ 
        super(props); 
        window.scrollTo(0, 0); 
        this.state = {loading: true, elements: [] }; 
        this.svg = new SVG()
        this.importHandler = new ComponentImportHandler();
        document.querySelectorAll('title')[0].textContent='Angebote | Calypso Software';
        document.querySelectorAll('html')[0].setAttribute('lang', 'de')}

    async componentDidMount(){ 
        this.content = await this.fetchContent()

        this.createContent()
    }

    fetchContent = async () => {
        return await fetch('/content.json',{method: 'GET'})
        .then(response => response.json())
        .then(response => response )
        .catch( async (err) => {
            console.log(err) 
        })
    }
    createContent(){
        var content = this.content
        var elems = [], index=0;
        content.features.forEach( content => {
            elems.push( columns(
                content, 
                index === 0 ? this.svg.support() : index === 1 ? this.svg.secure() : index === 2 ? this.svg.customize() : index === 3 ? this.svg.mobile() : index === 3 ? this.svg.code() : index === 4 ? this.svg.code() : index === 5 ? this.svg.api() : this.svg.unique(),
                index
            ))
            index++;
        })
        let firstEL = elems.shift()
        elems.push(firstEL)
        this.setState({ loading: false, elements: elems})
    }
    
    render(){
        return(
            <div id="container-products-hydrate">
            
        {this.state.loading ? this.importHandler.FullLoader() :             
            <>                
                
                { this.importHandler.NavMenu() }
                { new ComponentImportHandler().Logo() }
                
                    <div style={{zIndex: '40', width: '100%', height: 'auto', display: 'flex', flexDirection: 'column' , justifyContent: 'center', alignItems: 'center', paddingTop: '70px'}}>
                    { this.importHandler.Waves() }
                            <br/><br/>
                        <h2 className="__h1" style={{fontWeight: 'bold',paddingBottom: '300px', paddingTop: '300px',fontFamily: 'var( --font-family)', zIndex: '10', fontSize:  '1.8rem'}}>
                                
                                Produkte und Serices
                                <hr/>
                            </h2>
                            <br/><br/><br/><br/>
                        <section aria-label="Product-Description" className="marketing-site-three-up" style={{marginTop:'50px'}}>
                        
                            <div className="row medium-unstack">
                                { this.state.elements }                    
                            </div>
                        </section>
                    </div>

                    <br /><br /><br /><br />
                    <ProductContent content={ this.content } />
                    <DescriptionComp content={ this.content }/>
                    <br />
              
                { this.importHandler.Footer() }    
                {this.importHandler.MenuComp()}
            </>
        }

        </div>
        ) 
    }
}


const columns = (content, svg, key) => {

    return (
        <div className="columns glas-card-effect" key={key} style={{maxWidth:'780px'}}>
            {svg}
            
            <h4 aria-label={content.title} style={{marginTop: '10px', marginBottom: '50px', fontSize: '1.8rem'}} className="marketing-site-three-up-title">{content.title}</h4>
            <hr/>
            <p className="marketing-site-three-up-desc">{content.text}</p>
        </div>
    )
}