
export default class CookieHandler {
    constructor(options) {
        this.currentCookies = this.parseSessionCookie();        
    }

    createSessionCookie(){         
        this.writeSessionCookie(false); return    
    }
    proofCookieValues( _name, _value ){
        try{            
            var cookieExists, cookieValue = false;
            var storedCookies = document.cookie;
            storedCookies = storedCookies.split(';')
            storedCookies.forEach( cookie => {
                var [name, value] = cookie.split('=');
                name = name.trim(); value = value.trim();
                if( name === _name ){ 
                    cookieExists = true;  
                    if(value === _value ){
                        cookieValue = true;
                    }
                    else{ cookieValue = false; }
                }             
            })
            var result = { 
                existing: cookieExists,
                value: cookieValue
            }
            return result;
        }catch(e){
            
            return { existing: true, value: true };
        }
    }

    parseSessionCookie(){
        var list = [];
        var UID_COOKIE = document.cookie;
        if(!UID_COOKIE){ return [] }
        UID_COOKIE.split(';').forEach(cookie => {
            var [name, value] = cookie.split('=');
            var current = { name: name.trim(), value: value.trim() }
            list.push( current )
        })
        return list;
    }

    writeSessionCookie(connectionToServer){
        var d = new Date();
        d.setTime(d.getTime() + (24*60*60*1000));
        var expires = `expires=${d.toUTCString()};`

        if(connectionToServer){ 

            var val = this.responseValues;
            if(!val){ return }
            let cname = val.name; let cvalue = val.value;
            document.cookie =`${cname}=${cvalue};${expires};path=/;SameSite=None;Secure;`;
            // flavor=choco; SameSite=None; Secure
        }else document.cookie = `s=inital;${expires};path=/;SameSite=None;Secure;`;
        
    }

}