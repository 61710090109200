import React from 'react';
import './Styles.css';
import PreviewLink from '../../Middleware/Peekalink';
import LoadingScreen from '../LoadingScreen/loadingComp';
import ComponentImportHandler from '../import/ImportHandler';
import { Link } from 'react-router-dom'

let boxElements;
let boxElement;
let prevRatio = 0.0;




function createObserver() {
    let observer;
  
    let options = {
      root: null,
      rootMargin: window.innerWidth > 786 ? "-250px" : '0px',
      threshold: buildThresholdList()
    };
  
    observer = new IntersectionObserver(handleIntersect, options);
    observer.observe(boxElement);
  }
function buildThresholdList() {
    let thresholds = [];
    let numSteps = 35;
  
    for (let i=1.0; i<=numSteps; i++) {
      let ratio = i/numSteps;
      thresholds.push(ratio);
    }
  
    thresholds.push(0);
    return thresholds;
  }

function handleIntersect(entries, observer) {
    entries.forEach((entry) => {
      if (entry.intersectionRatio > prevRatio) {
        try{entry.target.classList.remove('zero-opacity')}catch(e){}
        entry.target.classList.add('active')
      } 
  
      prevRatio = entry.intersectionRatio;
    });
}
export default class Container extends React.Component {
    componentDidMount() {
        // Set things up       
        boxElements = document.querySelectorAll(".animate-selector");
        for( var el of boxElements ){
            boxElement = el
            createObserver()
        }
  
    }
    
    app_column = () => {
        

        return(
            <>
            <div className="app_column_container animate-selector left" aria-label={ this.props._arialabel } >

                <div className="container__svg_business">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                    width="90.000000pt" height="90.000000pt" viewBox="0 0 90.000000 90.000000"
                    preserveAspectRatio="xMidYMid meet">

                    <g transform="translate(0.000000,90.000000) scale(0.100000,-0.100000)"
                    fill="#fff" stroke="none">
                    <path d="M410 850 c-11 -11 -20 -24 -20 -29 0 -5 -53 -39 -118 -74 -87 -48
                    -124 -64 -144 -60 -18 3 -33 -2 -47 -16 -25 -25 -27 -50 -6 -77 21 -27 21
                    -261 0 -288 -21 -27 -19 -52 6 -77 14 -14 29 -19 47 -16 34 7 258 -114 267
                    -143 15 -47 86 -50 107 -5 16 35 235 155 271 148 17 -3 32 2 46 16 25 25 27
                    50 6 77 -21 27 -21 261 0 288 21 27 19 52 -6 77 -14 14 -29 19 -46 16 -19 -3
                    -59 13 -144 60 -66 36 -119 69 -119 74 0 17 -40 49 -60 49 -11 0 -29 -9 -40
                    -20z m195 -158 c141 -79 145 -84 145 -243 0 -155 -4 -162 -146 -242 -146 -80
                    -156 -81 -302 -1 -148 80 -152 86 -152 244 0 158 4 165 152 245 144 79 155 79
                    303 -3z"/>
                    <path d="M389 631 c-20 -20 -29 -39 -29 -61 0 -43 47 -90 90 -90 43 0 90 47
                    90 90 0 43 -47 90 -90 90 -22 0 -41 -9 -61 -29z"/>
                    <path d="M359 391 c-24 -24 -29 -38 -29 -75 l0 -46 120 0 120 0 0 46 c0 37 -5
                    51 -29 75 -26 26 -36 29 -91 29 -55 0 -65 -3 -91 -29z"/>
                    </g>
                    </svg>
                </div>
                <h1>Professionelle Website erstellen lassen</h1>
                <hr/>
                <p className="marketing-site-three-up-desc">
                    Wir programmieren Websites und Apps sowie Web-Apps, noch nach alter Schule was bedeutet, dass
                    wir nicht einfach Layouts zu einer Website zusammenschieben, 
                    wir erstellen Websites und Apps noch mit der Flexibilität die nur wirklichen Programmierern
                    zur Verfügung steht.
                    <br/>
                    <br/>
                    Dadurch können sie sich mit Ihrem Projekt sich von der Masse, durch hochwertige Indiviualität, 
                    abheben und mit 
                    Funktionen durchstarten die es bei Wordpress sicherlich nicht so einfach gibt. 
                </p>
                <p className="marketing-site-three-up-desc" >
                Durch unsere langjährige Erfahrung bei der Konzeptionierung, Design, 
                und Programmierung von Websites und Apps 
                sind wir in der Lage auch anspruchsvolle Projekte, 
                in enger Abstimmung mit unseren Kunden, zu erstellen.                                 
                </p>
                <p className="marketing-site-three-up-desc">
                In einer sich immer schneller entwickelnden Zeit ist es von Bedeutung sich ebenfalls an 
                den Wandel mit anzupassen und die Vorteile dessen voll auszuschöpfen.
                </p>
                <p className="marketing-site-three-up-desc">
                Wir verwenden die selben Technologien und
                die auch von großen Firmen genutzt wird um die Ansprüche der Zukunft, 
                mit den Projekten von heute, zu bewältigen.</p>
            </div>
            </>
        )
    }
    app_column_1 = () => {
        return(
            <>
                <div className="app_column_container animate-selector left" aria-label={ this.props._arialabel }>
                    <div className="container__svg_business">

                    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                        width="90.000000pt" height="90.000000pt" viewBox="0 0 100.000000 100.000000"
                        preserveAspectRatio="xMidYMid meet">
                        <g transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)"
                        fill="#fff" stroke="none">
                        <path d="M840 981 c0 -12 -14 -26 -37 -38 -50 -24 -70 -77 -48 -128 11 -29 28
                        -42 102 -77 48 -24 91 -50 97 -59 15 -26 -2 -67 -34 -84 -66 -34 -139 -7 -156
                        59 -4 17 -13 26 -25 26 -46 0 -2 -92 58 -121 28 -14 43 -27 43 -40 0 -12 7
                        -19 20 -19 13 0 20 7 20 19 0 13 11 23 34 31 45 14 76 49 83 93 9 55 -15 83
                        -112 127 -94 43 -113 65 -93 107 28 58 110 55 138 -4 14 -31 50 -46 50 -22 0
                        30 -35 79 -66 94 -22 10 -34 23 -34 35 0 13 -7 20 -20 20 -13 0 -20 -7 -20
                        -19z"/>
                        <path d="M104 815 l-25 -25 3 -255 3 -255 -28 0 c-34 0 -43 -21 -27 -61 25
                        -58 30 -59 470 -59 440 0 445 1 470 59 16 40 7 61 -27 61 l-28 0 3 90 c3 85 2
                        90 -17 90 -19 0 -21 -6 -21 -78 0 -47 -5 -83 -12 -90 -17 -17 -719 -17 -736 0
                        -17 17 -17 479 0 496 9 9 88 12 295 12 276 0 283 0 283 20 0 20 -7 20 -290 20
                        l-291 0 -25 -25z"/>
                        </g>
                    </svg>

                    </div>
                    <h2 style={{fontSize: '2rem'}}>Passives Einkommen verdienen</h2>
                    <hr/>
                    <p>
                    Eine Webseite oder auch Apps die Blogs, Tutorials, Informationen und vieles mehr darstellt, kann monetarisiert werden.
                    Das bedeutet, dass sie mittels Werbung auf ihrer Seite passives Einkommen generieren.  

                    </p>
                    <p>
                        Durch die Zusammenarbeit von uns mit Anbietern wie Google AdSense, Amazon Native Shopping Ads, Skimlinks etc, 
                        werden auf ihrer Domain nur Werbungen gezeigt die Sie zulassen und die zu Ihrer Zielgruppe passt.
                    </p>
                    <p>
                        Ab einer monatlichen Besucherzahl von 50 Tsd. 
                        können sie schon je nach Anzeige oder Monetarisierungsverfahren bis zu 2500 EUR einnehmen. 
                        Insofern die Anzahl der Aufrufe steigt, vermehrt sich auch Ihr potenzielles Einkommen.
                    </p>
                    <p>
                        Selbstverständlich benötigt ihre Website aber erstmal ein gewisses Aufkommen an Aufrufen, 
                        um erfolgreich monetarisiert werden zu können.
                        <br/>
                        <br/>
                        Genau aus diesem Grund steht Benutzerfreundlichkeit, 
                        Einzigartigkeit sowie die Suchmaschinen Optimierung, auch SEO genannt, 
                        an vorderster Front
                        bei der Konzeptionierung und Umsetzung eines Projektes. 
                    </p>
                </div>
            </>
        )
    }

    app_column_2 = () => {
        return(
            <>
                <div className="app_column_container animate-selector left" aria-label={ this.props._arialabel }>
                    <div className="container__svg_business">

                    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                        width="90.000000pt" height="90.000000pt" viewBox="0 0 100.000000 100.000000"
                        preserveAspectRatio="xMidYMid meet">

                        <g transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)"
                        fill="#fff" stroke="none">
                        <path d="M272 985 c-73 -21 -108 -40 -165 -93 -123 -115 -140 -288 -42 -416
                        19 -26 35 -52 35 -60 0 -7 -22 -34 -50 -59 -27 -25 -50 -51 -50 -56 0 -37 108
                        -38 211 -2 l56 19 18 -34 c59 -109 241 -198 366 -178 27 5 60 -1 116 -21 97
                        -34 190 -43 218 -22 19 15 18 17 -39 77 l-59 61 25 27 c89 98 109 231 52 349
                        -30 63 -117 145 -178 168 -34 13 -48 25 -62 57 -26 57 -122 142 -191 168 -75
                        28 -190 35 -261 15z m253 -54 c148 -66 222 -209 179 -348 -14 -45 -31 -71 -74
                        -114 -74 -75 -141 -100 -270 -103 -69 -2 -111 -9 -153 -25 -32 -12 -78 -24
                        -103 -28 l-44 -6 39 40 c57 56 61 85 21 126 -56 56 -75 103 -75 187 0 65 4 82
                        30 129 43 77 123 138 212 161 60 15 184 5 238 -19z"/>
                        </g>
                    </svg>

                    </div>
                    <h2 style={{fontSize: '2rem'}}>Wie viel kostet eine Website</h2>
                    <hr/>
                    <p>
                    Der Preis einer Website oder Webanwendung ist individuell und hängt stark vom Umfang und 
                    Komplexität der gewünschten Seite ab. 

                    Kleine Webseiten können schon für eine verhältnismäßig geringeren Investition erstellt werden. 
                    </p>
                    <p>
                        Bei uns bekommen sie schon für lediglich <strong>699 EUR</strong> eine 
                        Suchmaschinenoptimerte Website mit bis zu 5 Seiten,
                        ganz nach Ihren Vorstellungen. Über unser 
                        <a href='#contact-container-id' ><strong> Kontaktformular </strong></a>
                        können sie uns jederzeit für eine kostenlose Erstberatung erreichen.
                    </p>
                </div>
            </>
        )
    }

    app_column_3 = () => {
        return(
            <>
            <div className="app_column_container animate-selector left" aria-label={ this.props._arialabel }>
                <div className="container__svg_business">

                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                    width="90.000000pt" height="90.000000pt" viewBox="0 0 50.000000 50.000000"
                    preserveAspectRatio="xMidYMid meet">

                    <g transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)"
                    fill="#fff" stroke="none">
                    <path d="M100 452 c-56 -28 -70 -68 -70 -202 0 -192 28 -220 220 -220 192 0
                    220 28 220 220 0 134 -14 174 -70 202 -50 26 -250 26 -300 0z m286 -73 c10
                    -17 -13 -36 -27 -22 -12 12 -4 33 11 33 5 0 12 -5 16 -11z m-86 -34 c32 -17
                    60 -61 60 -95 0 -53 -57 -110 -110 -110 -53 0 -110 57 -110 110 0 33 28 78 58
                    94 35 20 66 20 102 1z"/>
                    <path d="M189 311 c-20 -20 -29 -39 -29 -61 0 -43 47 -90 90 -90 43 0 90 47
                    90 90 0 43 -47 90 -90 90 -22 0 -41 -9 -61 -29z"/>
                    </g>
                </svg>


                </div>
                <h2 style={{fontSize: '2rem'}}>Social Media Werbung</h2>
                <hr/>
                <p>
                    Der größte Teil der Bevölkerung bewegt sich auf Social Media. 
                    Insofern können sie mittels Werbung für Ihren Online-Shop 
                    aufmerksam machen und potenzielle Kunden akquirieren. 
                </p>
                <p>
                Der Traffic oder anders gesagt die Aufrufe für Ihre Webseite steigt stark durch cleveres Marketing. 
                Plattformen wie Facebook, Twitter usw., bieten alles was Sie benötigen. 
                Kurz gesagt ein nützliches Werbe-Tool.
                </p>
                <p>
                    Durch Werbung auf Social Media kann Ihre Seite direkt verlinkt werden, 
                    sodass Ihre Zielgruppe direkt, beispielsweise von Instagram, auf 
                    die Angebote Ihrer Seite zugreifen kann. 
                </p>
                <p>
                    Gerade, wenn eine Website neu online geht und erst noch von den 
                    verschiedenen Suchmaschinen indexiert werden muss,
                    fällt der Traffic meist eher gering aus, weshalb sich Social-Media-Marketing sehr gut eignet, 
                    um durch die Werbung 
                    generierten Aufrufe, beim Suchmaschinen-Ranking höher platziert zu werden, 
                    sodass die Website auch in den 
                    Suchergebnissen weiter oben gelistet wird.  
                    
                </p>
            </div>
        </>
        )
    }

    app_column_4 = () => {
        return(
            <>
            <div className="app_column_container animate-selector left" aria-label={ this.props._arialabel }>
                <div className="container__svg_business">

                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                    width="90.000000pt" height="90.000000pt" viewBox="0 0 100.000000 100.000000"
                    preserveAspectRatio="xMidYMid meet">

                    <g transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)"
                    fill="#fff" stroke="none">
                    <path d="M508 874 c-15 -31 -19 -47 -12 -52 6 -4 23 -30 38 -59 l26 -52 0
                    -256 0 -255 178 2 177 3 3 308 2 307 -25 50 -25 50 -170 0 -170 0 -22 -46z
                    m352 -14 c11 -20 8 -20 -160 -20 -168 0 -171 0 -160 20 10 19 21 20 160 20
                    139 0 150 -1 160 -20z m-20 -160 l0 -40 -120 0 -120 0 0 40 0 40 120 0 120 0
                    0 -40z m0 -120 l0 -40 -120 0 -120 0 0 40 0 40 120 0 120 0 0 -40z m-104 -151
                    c10 -17 -13 -36 -27 -22 -12 12 -4 33 11 33 5 0 12 -5 16 -11z m0 -80 c10 -17
                    -13 -36 -27 -22 -12 12 -4 33 11 33 5 0 12 -5 16 -11z"/>
                    <path d="M105 750 l-25 -50 2 -307 3 -308 215 0 215 0 3 308 2 307 -25 50 -25
                    50 -170 0 -170 0 -25 -50z m355 -10 c11 -20 8 -20 -160 -20 -168 0 -171 0
                    -160 20 10 19 21 20 160 20 139 0 150 -1 160 -20z m-40 -160 l0 -40 -120 0
                    -120 0 0 40 0 40 120 0 120 0 0 -40z m0 -120 l0 -40 -120 0 -120 0 0 40 0 40
                    120 0 120 0 0 -40z m-104 -151 c10 -17 -13 -36 -27 -22 -12 12 -4 33 11 33 5
                    0 12 -5 16 -11z m0 -80 c10 -17 -13 -36 -27 -22 -12 12 -4 33 11 33 5 0 12 -5
                    16 -11z"/>
                    </g>
                </svg>

                </div>
                <h2 style={{fontSize: '2rem'}}>Hosting Dienste</h2>
                <hr/>
                <p>
                    Um eine Webseite betreiben zu können bedarf es einer Domain beispielsweise my-example.com.
                    Über verschiedene Platformen lässt sich die Domain anmelden wenn sie Verfügbar ist.
                    Sie können die Verfügbarkeit gerne hier Testen.
                </p>
                <p>
                Ob sie einen Server benötigen hängt von der Anwendung ab. 
                In der Regel reicht es über den Domain Anbieter eine einfache Webseiten zu hosten ohne extra Server.
                Komplexere Projekte wie Online Shops, 
                Social Media ähnliche Anwendungen benötigen einen Server der über Anbieter wie AWS und viele mehr, 
                gehostet werden kann. 
                Optional kann man auch über seine private Hardware Server und Webseite hosten, 
                birgt aber entscheidende Nachteile 
                im Bezug auf zuverlässige Erreichbarkeit und Security.
                </p>
                <p>
                    Aber auch diese Aspekte lassen sich am besten im persönlichen <a href="#contact-container-id"><strong>Kontakt</strong> </a>besprechen.
                </p>

                <p>
                    Selbstverständlich begleiten wir sie durch den gesamten Prozess vom Start des Projektes bis hin 
                    zur Veröffentlichung <strong>ohne extra Gebühren</strong>, insofern Sie das möchten.
                </p>
                <br/>
             
            </div>
        </>

        )
    }

    app_column_5 = () => {
        return(
            <>
            <div className="app_column_container animate-selector left" aria-label={ this.props._arialabel }>
                <div className="container__svg_business">

                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                    width="90.000000pt" height="90.000000pt" viewBox="0 0 90.000000 90.000000"
                    preserveAspectRatio="xMidYMid meet">

                    <g transform="translate(0.000000,90.000000) scale(0.100000,-0.100000)"
                    fill="#fff" stroke="none">
                    <path d="M465 780 c9 -18 15 -33 13 -35 -2 -1 -83 -44 -180 -95 -138 -71 -178
                    -96 -178 -111 0 -11 7 -22 15 -26 9 -3 92 33 195 86 l180 92 12 -25 c6 -14 14
                    -26 18 -26 9 0 86 147 80 153 -3 2 -42 8 -88 11 l-83 7 16 -31z"/>
                    <path d="M667 594 c-4 -4 -7 -112 -7 -241 l0 -233 60 0 60 0 -2 238 -3 237
                    -50 3 c-28 2 -54 0 -58 -4z"/>
                    <path d="M487 504 c-4 -4 -7 -92 -7 -196 l0 -188 60 0 60 0 -2 193 -3 192 -50
                    3 c-28 2 -54 0 -58 -4z"/>
                    <path d="M307 414 c-4 -4 -7 -72 -7 -151 l0 -143 60 0 61 0 -3 148 -3 147 -50
                    3 c-28 2 -54 0 -58 -4z"/>
                    <path d="M127 324 c-4 -4 -7 -52 -7 -106 l0 -98 60 0 61 0 -3 103 -3 102 -50
                    3 c-28 2 -54 0 -58 -4z"/>
                    </g>
                </svg>


                </div>
                <h2 style={{fontSize: '2rem'}}>SEO Optimierung</h2>
                <hr/>
                <p>
                Damit Ihre Website gefunden werden kann gibt es verschiedene Kriterien die die Webseite erfüllen sollte.
                <br/>
                Zu einem muss, durch die Verwendung von gewissen Schlüsselwörtern in Texten und in der Grundstruktur, die Webseite 
                zu den Suchanfragen, der Google Nutzer passen, damit diese angezeigt wird.
                </p>
                <p>Umso besser der Content einer Website zu den Suchanfragen der Nutzer passt, umso höher wird die Seite bei Google angezeigt und für spätere, ähnliche Anfragen, favorisiert.</p>
                <p>Und genau darum dreht sich das Thema SEO, es soll eine möglichst hohe Wertung bei den Suchmaschinen erreicht werden, damit Ihre Seite den Suchenden als erstes angezeigt wird.</p>
                <br/>
                <p>
                Unsere talentierten SEO Beauftragten sind speziell ausgebildet im Bereich der Suchmaschinen Optimierung.
                So geht ihre Home Page nicht in den Tiefen des Internets unter und wird Ihrer Zielgruppe weiter oben vorgeschlagen.
                </p>
                <p>
                    Wie schon im oberen Abschnitt angedeutet ist die Suchmaschinen-Optimierung ein absolut essenzieller Bestandteil, 
                    einer jeden Website. 
                    Durch Einzigartigkeit und Texten der zur Zielgruppe passt, ist ein gutes Ranking möglich.
                    Durch das geschickte einsetzen von Schlüsselwörtern und aussagekräftigem Content,
                    lässt sich die SEO Optimierung noch aufbessern. 

                </p>
            </div>
        </>

        )
    }
    app_column_6 = () => {
        return(
            <>
            <div className="app_column_container animate-selector left" aria-label={ this.props._arialabel }>
                <div className="container__svg_business">

                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                    width="90.000000pt" height="90.000000pt" viewBox="0 0 90.000000 90.000000"
                    preserveAspectRatio="xMidYMid meet">

                    <g transform="translate(0.000000,90.000000) scale(0.100000,-0.100000)"
                    fill="#fff" stroke="none">
                    <path d="M200 850 c-19 -19 -20 -33 -20 -400 0 -367 1 -381 20 -400 19 -19 33
                    -20 250 -20 217 0 231 1 250 20 19 19 20 33 20 400 0 367 -1 381 -20 400 -19
                    19 -33 20 -250 20 -217 0 -231 -1 -250 -20z m280 -25 c0 -11 -12 -15 -45 -15
                    -33 0 -45 4 -45 15 0 11 12 15 45 15 33 0 45 -4 45 -15z m60 0 c0 -8 -7 -15
                    -15 -15 -8 0 -15 7 -15 15 0 8 7 15 15 15 8 0 15 -7 15 -15z m-190 -30 c18
                    -21 182 -21 200 0 8 10 30 15 61 15 l49 0 0 -360 0 -360 -210 0 -210 0 0 360
                    0 360 49 0 c31 0 53 -5 61 -15z"/>
                    <path d="M420 630 c0 -19 -5 -30 -14 -30 -8 0 -27 -11 -43 -24 -22 -19 -28
                    -32 -28 -66 0 -53 24 -74 101 -90 60 -13 79 -34 49 -56 -26 -19 -52 -17 -75 6
                    -11 11 -33 20 -50 20 -23 0 -30 -4 -30 -20 0 -25 32 -58 65 -66 19 -5 25 -13
                    25 -35 0 -26 4 -29 30 -29 26 0 30 3 30 29 0 22 6 30 25 35 38 10 65 45 65 86
                    0 52 -18 70 -90 89 -47 12 -66 22 -68 35 -8 38 46 48 79 15 27 -27 75 -23 71
                    6 -4 23 -47 65 -68 65 -9 0 -14 11 -14 30 0 27 -3 30 -30 30 -27 0 -30 -3 -30
                    -30z"/>
                    </g>
                </svg>

                </div>
                <h2 style={{fontSize: '2rem'}}>Responsive Design optimiert für mobile Endgeräte</h2>
                <hr/>
                <p>
                Der größte Teil der Internetnutzer besucht Webseiten über ihr Handy, weshalb es wichtig ist das eine 
                Home Page für alles Plattformen optimiert wird. 

                </p>
                <p>
                    Die Anpassung der Internetseite auf mobile Endgeräte wie Smartphones und Tablets ist seit 2015 offizieller 
                    Rankingfaktor und wird von Google für die Bewertung von Webseiten herangezogen. 
                    Im Jahr 2015 entfielen fast 60% aller Seitenaufrufe auf mobile Endgeräte.
                </p>
                <p>
                    Es ist in der heutigen Zeit ein Muss für jede website auf allen Endgeräten zu performen, 
                    als auch die Benutzerfreundlichkeit zu wahren. 
                    <br/>
                    Weshalb jede Website zu erst für Mobile Endgeräte optimiert wird, 
                    um sowohl auf einem 786 Pixel, als auch auf einem 2500 Pixel, breitem Bildschirm
                    die Form zu behalten.   
                </p>
            </div>
        </>

        )
    }

    app_column_7 = () => {
        return(
            <>
            <div className="app_column_container animate-selector left" aria-label={ this.props._arialabel }>
                <div className="container__svg_business">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                    width="90.000000pt" height="90.000000pt" viewBox="0 0 90.000000 90.000000"
                    preserveAspectRatio="xMidYMid meet">

                    <g transform="translate(0.000000,90.000000) scale(0.100000,-0.100000)"
                    fill="#fff" stroke="none">
                    <path d="M140 760 c-19 -19 -20 -33 -20 -310 0 -277 1 -291 20 -310 19 -19 33
                    -20 310 -20 277 0 291 1 310 20 19 19 20 33 20 310 0 277 -1 291 -20 310 -19
                    19 -33 20 -310 20 -277 0 -291 -1 -310 -20z m548 -333 c12 -11 22 -26 22 -34
                    0 -19 -56 -17 -64 3 -8 21 -60 15 -64 -8 -2 -12 8 -20 40 -28 68 -18 88 -35
                    88 -75 0 -53 -28 -75 -95 -75 -42 0 -60 5 -75 20 -43 43 -10 67 48 33 33 -19
                    65 -11 60 14 -2 10 -20 22 -47 30 -56 16 -81 39 -81 73 0 26 29 61 60 73 26
                    10 84 -4 108 -26z m-238 -71 c0 -79 -3 -97 -21 -120 -40 -51 -149 -26 -149 34
                    0 25 45 28 58 4 5 -9 18 -14 28 -12 17 3 19 14 22 96 l3 92 29 0 30 0 0 -94z"/>
                    </g>
                </svg>

                </div>
                <h2 style={{fontSize: '2rem'}}>Welche Technologien wir nutzen</h2>
                <hr/>
                <p>
                    Um auch komplexere Anwendungen entwickeln zu können nutzen wir für das Frontend, 
                    also das was der Endnutzer sieht, 
                    React. 
                    <br/>
                    React ist eine JavaScript Bibliothek von damals Facebook heute Meta, 
                    die die Entwicklung von anspruchsvollen Anwendungen,
                    wie Online Shops oder Social Media ähnliche Software, erheblich vereinfacht.
                    <br/>
                    Im Prinzip lässt sich mithilfe von React alles programmieren und das Plattform übergreifend, 
                    weshalb es sich in der Entwickler Szene großer Beliebtheit erfreut.   
                </p>
                <p>
                    Selbstverständlich sind Server das Herzstück einer komplexeren Anwendung. 
                    Nodejs eignet sich hervorragend für die Bereitstellung eines Backends welches mit viel Traffic umgehen muss.  
                    
                    Durch die speziellen Eigenschaften die Node bietet können auch Applikationen wie 
                    Echtzeit-Chats ohne Leistungsverlust umgesetzt werden.
                    <br/>
                    <br/>
                    Node ist ein Framework für JavaScript welches Serverseitiges JavaScript ermöglicht, 
                    Konzerne wie PAYPAL, LINKEDIN, NETFLIX, MOZILLA, EBAY und viele mehr, nutzen Nodejs 
                    und heben die Grenze zwischen Back- und Frontend auf. 
                </p>
            </div>
        </>

        )
    }

    app_column_8 = () => {
        return(
            <>
            <div className="app_column_container animate-selector left" aria-label={ this.props._arialabel }>                  
                { new ComponentImportHandler().ContactForm() }

            </div>
        </>

        )
    }
    app_column_9 = () => {
        return(
            <>
            <div className="app_column_container animate-selector left" aria-label={ this.props._arialabel }>
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                width="90.000000pt" height="90.000000pt" viewBox="0 0 90.000000 90.000000"
                preserveAspectRatio="xMidYMid meet">

                <g transform="translate(0.000000,90.000000) scale(0.100000,-0.100000)"
                fill="#fff" stroke="none">
                <path d="M94 827 c-2 -7 -3 -167 -2 -355 l3 -342 33 -32 32 -33 305 0 305 0
                32 33 33 32 0 248 0 247 -42 3 -43 3 -2 -238 -3 -238 -25 0 -25 0 -5 340 -5
                340 -293 3 c-237 2 -293 0 -298 -11z m506 -152 l0 -45 -210 0 -210 0 0 45 0
                45 210 0 210 0 0 -45z m-240 -195 l0 -30 -90 0 -90 0 0 30 0 30 90 0 90 0 0
                -30z m240 0 l0 -30 -90 0 -90 0 0 30 0 30 90 0 90 0 0 -30z m-240 -120 l0 -30
                -90 0 -90 0 0 30 0 30 90 0 90 0 0 -30z m240 0 l0 -30 -90 0 -90 0 0 30 0 30
                90 0 90 0 0 -30z m-240 -120 l0 -30 -90 0 -90 0 0 30 0 30 90 0 90 0 0 -30z
                m240 0 l0 -30 -90 0 -90 0 0 30 0 30 90 0 90 0 0 -30z"/>
                </g>
                </svg>
                <h2 style={{fontSize: '2rem'}}>Schauen Sie auch gerne in unserem Nachrichten-Ticker vorbei</h2>
                <br/>

                <p style={{fontSize: '1.2rem'}}>
                    Dieser liefert werbefrei die tagesaktuellen Themen aus Deutschland und dem Rest der Welt.
                </p>
                <p style={{fontSize: '1.2rem'}}>
                    Selbstverständlich 
                    können die Artikel, ohne Werbung, und die meisten, via Klick, direkt auf dem Newsletter, 
                    in voller Länge gelesen werden.  
                    <br/>
                    <br/>
                    <strong>Viel Spaß beim stöbern.</strong>                     
                </p>
                <hr/>
                <Link to="/realtime-news" style={{textDecoration: 'none'}} >
                    <button style={{paddingLeft: '10px', paddingRight: '10px'}} className="Input-Standart b unique-sub __avail_btn"><em>Newsletter</em></button>
                </Link>
                <br/>
                <br/>
            </div>
            </>
        )
    }





    render(){
        return(
            <>
            { this.props.app_column ? this.app_column() :  <></> }
            { this.props.app_column_1 ? this.app_column_1() : <></> }
            { this.props.app_column_2 ? this.app_column_2() : <></> }
            { this.props.app_column_3 ? this.app_column_3() : <></> }
            { this.props.app_column_4 ? this.app_column_4() : <></> }
            { this.props.app_column_5 ? this.app_column_5() : <></> }
            { this.props.app_column_6 ? this.app_column_6() : <></> }
            { this.props.app_column_7 ? this.app_column_7() : <></> }
            { this.props.app_column_8 ? this.app_column_8() : <></> }
            { this.props.app_column_9 ? this.app_column_9() : <></> }
            </>
        ) 
    }
}

/* 
class CheckDomain extends React.Component {
    constructor(props) {
        super(props);
        this.state={available: null, loading: false, endingExist: true, style: {

            border: 'none', fontSize: '.9rem', fontWeight: '700'
        } }
        this.input_domain='';
        this.handleChange = this.handleChange.bind(this);

    }
    handleChange = (e) => {
        e.preventDefault();

        this.input_domain = e.target.value;
        
    }
    _checkDomain = async () => {
        if( !this.input_domain || this.input_domain.indexOf('.') === -1 ) {
            this.setState({style: {               
                border: '1px solid red', fontSize: '.9rem', fontWeight: '700'
            }})
            return;
        };
        this.setState({loading: true, style: {border: 'none', fontSize:'.9rem', fontWeight: '700'}})
        var res = await PreviewLink( this.input_domain );
        res === undefined ? this.setState({loading: false, available: false, endingExist: false}) 
        : res ? this.setState({available: true, loading: false, endingExist: true}) 
        : this.setState({available: false, loading: false, endingExist: true})        
    }
    resultOfChecking = () => {
        return(
            <>
              { this.state.available === null ? <></> 
              : !this.state.endingExist ? <p>Die Domainendung existiert leider nicht.</p> 
              : this.state.available ? <p>Die gewünschte Domain ist verfügbar.</p> 
              : <p>Die gewünschte Domain ist vergeben..</p> }  
            </>
        )
    }
    render(){
        return(
            <section id="check-domain" aria-label="domain server">
                <input
                aria-label="Texteingabe" 
                tabindex={1}
                style={this.state.style} autoCorrect={"off"} className={ this.state.available ? "Input-Standart Text-Input __avail  Available" : "__avail Input-Standart Text-Input notAvailable" } 
                type="text" name="url_check" placeholder="Wunsch Domain" 
                onChange={ e => this.handleChange(e) }/>
                <div className="container__svg_business" style={{cursor:'pointer !important'}}>
                { !this.state.loading ? this.resultOfChecking() : <LoadingScreen smallLoader={true} />}
                </div>
                <div style={{ width:'100%', height:'auto', display:'flex', justifyContent: 'center', alignItems: 'center', margin: '5px'}}>
                <button
                tabIndex={2} 
                aria-label={'Domain-Prüf-Button'}
                className="Input-Standart b unique-sub __avail_btn"  
                onClick={this._checkDomain}>Prüfe deine Wunsch Domain</button>
                </div>
            </section>
        )
    }
}
*/