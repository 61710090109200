import React from 'react';
import './Style.scss'
import { Link } from 'react-router-dom';
import ComponentImportHandler from '../import/ImportHandler';

export default class ErrorPage extends React.Component {
    constructor(props) {super(props);window.scrollTo(0, 0)}
    componentDidMount() {

        document.querySelectorAll('html')[0].setAttribute('lang', 'de')
        document.querySelectorAll('title')[0].innerText= 
        !this.props.textContent 
        ? 'Calypso Software | Error 404' : 
        `${document.querySelectorAll('title')[0].text}` ;        
    }

    render() {
        return (
            <div id="error-404-container">
            { new ComponentImportHandler().NavMenu() }
            { new ComponentImportHandler().Logo() }
            <div className="Container-ErrorPage" aria-label="404 Page leider wurde die Seite nicht gefunden">
            <div className="box">
                <div className="box__ghost">
                    <div className="symbol"></div>
                    <div className="symbol"></div>
                    <div className="symbol"></div>
                    <div className="symbol"></div>
                    <div className="symbol"></div>
                    <div className="symbol"></div>

                    <div className="box__ghost-container">
                        <div className="box__ghost-eyes">
                            <div className="box__eye-left"></div>
                            <div className="box__eye-right"></div>
                        </div>
                        <div className="box__ghost-bottom">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                    <div className="box__ghost-shadow"></div>
                </div>

                <div className="box__description">
                    <div className="box__description-container">               

                        {
                              this.props.textContent 
                              ? <div className="box__description-title">Ein Fehler ist aufgetreten..</div> 
                              : <div className="box__description-title">Whoops! 404</div>
                        }
                        {
                    
                            this.props.textContent 
                            ? <div className="box__description-text">{this.props.textContent}</div> :
                            <div className="box__description-text">Es sieht so aus als konnten wir die Seite die du suchst nicht finden.</div>

                        }
                    </div>
                    <Link tabindex="0" aria-label="Zurück zur Hompage" to="/" className="box__button">Zurück zur Home Page</Link>
                </div>
            </div>
            </div>


            {new ComponentImportHandler().Footer() }
            {new ComponentImportHandler().MenuComp() }
            </div>
        );
    }
    
}