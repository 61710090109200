import {React, Component, useState, useEffect} from 'react';
import { Link } from 'react-router-dom'
import '../Styles/HeaderV2.css'



const TopBarMenu = () => {
    const [isActive, setActive] = useState(false)

    // SET Header backgound if menu open
    useEffect(()=>{
        let header = document.querySelector('.header-v2-main')

        if(isActive){
            header.classList.add('v2-header-background-transition')
        } else if(!isActive && header.classList.contains('v2-header-background-transition')){
            header.classList.remove('v2-header-background-transition')
        }
    },[isActive])

    return (
        <>
        <div className="v2-iconbar">
            <div className="v2-icons v2-label-name">
                <span>Calypso Software</span>
            </div>

            <div className="v2-icons v2-icons-svg">
                    <img className="v2-icon"
                    onClick={ () => setActive(!isActive) }
                    src={'/icons8-menu-64.svg'}
                    alt="Menu"
                    width=""
                    height="" 
                    />
            </div>
        </div>
        <div className={ !isActive ? "v2-menubar" : "v2-menubar v2-clicked-menu"}>
            <div className="v2-menu-wrapper">
                <Link tabIndex={1} aria-label="Homepage-Link" to="/">Home</Link>
                <Link tabIndex={2} aria-label="About-Link"to="/blog">About</Link>
                <Link tabIndex={3} aria-label="Produkt-Link" to="/products">Services</Link>
                <Link tabIndex={4} aria-label="Newsletter-Link" to="/realtime-news">Newsletter</Link>
                <Link tabIndex={5} aria-label="Kontakformular-Link" to="/contact">Kontakt</Link>
            </div>
        </div>
        </>
    )
}

export default class Header extends Component {

    render() {
        return (
            <header className="header-v2-main">
                <div className="v2-header-container">
                    <TopBarMenu />
                </div>
            </header>
        )
    }
}
