import React from 'react';


export default class SVG extends React.Component {
    customize = () => { 
        return(            
            <svg xmlns="http://www.w3.org/2000/svg" x={"0px"} y={"0px"}
            width={"120"} height={"120"}
            viewBox={"0 0 30 30"}
            fill={"#fff"} >    
            <path d="M 22.828125 3 C 22.316375 3 21.804562 3.1954375 21.414062 3.5859375 L 19 6 L 24 11 L 26.414062 8.5859375 C 27.195062 7.8049375 27.195062 6.5388125 26.414062 5.7578125 L 24.242188 3.5859375 C 23.851688 3.1954375 23.339875 3 22.828125 3 z M 17 8 L 5.2597656 19.740234 C 5.2597656 19.740234 6.1775313 19.658 6.5195312 20 C 6.8615312 20.342 6.58 22.58 7 23 C 7.42 23.42 9.6438906 23.124359 9.9628906 23.443359 C 10.281891 23.762359 10.259766 24.740234 10.259766 24.740234 L 22 13 L 17 8 z M 4 23 L 3.0566406 25.671875 A 1 1 0 0 0 3 26 A 1 1 0 0 0 4 27 A 1 1 0 0 0 4.328125 26.943359 A 1 1 0 0 0 4.3378906 26.939453 L 4.3632812 26.931641 A 1 1 0 0 0 4.3691406 26.927734 L 7 26 L 5.5 24.5 L 4 23 z"></path></svg>
        )
    }
    secure = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" x={"0px"} y={"0px"}
            width={"90"} height={"90"}
            viewBox={"0 0 30 30"}
            fill={"#fff"}>
            <path d="M 15 2 C 11.145666 2 8 5.1456661 8 9 L 8 11 L 6 11 C 4.895 11 4 11.895 4 13 L 4 25 C 4 26.105 4.895 27 6 27 L 24 27 C 25.105 27 26 26.105 26 25 L 26 13 C 26 11.895 25.105 11 24 11 L 22 11 L 22 9 C 22 5.2715823 19.036581 2.2685653 15.355469 2.0722656 A 1.0001 1.0001 0 0 0 15 2 z M 15 4 C 17.773666 4 20 6.2263339 20 9 L 20 11 L 10 11 L 10 9 C 10 6.2263339 12.226334 4 15 4 z"></path></svg>
        )
    }
    support = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" x={"0px"} y={"0px"}
            width={"90"} height={"90"}
            viewBox={"0 0 30 30"}
            fill="#fff"
            >
            <path d="M 15 3 C 7.82 3 2 7.925 2 14 C 2 17.368 3.7933281 20.378484 6.6113281 22.396484 C 6.6832805 23.932468 6.4452784 26.053382 4.3261719 27.03125 A 0.5 0.5 0 0 0 4.3222656 27.033203 A 0.5 0.5 0 0 0 4 27.5 A 0.5 0.5 0 0 0 4.5 28 C 4.5119372 28 4.5232366 27.998109 4.5351562 27.998047 A 0.5 0.5 0 0 0 4.5429688 27.998047 C 6.9769949 27.982445 9.0432734 26.667034 10.46875 25.277344 C 10.92075 24.836344 11.550875 24.619328 12.171875 24.736328 C 13.081875 24.909328 14.028 25 15 25 C 22.18 25 28 20.075 28 14 C 28 7.925 22.18 3 15 3 z"></path></svg>
        )
    }
    api = () => {
        return (
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                width="90.000000pt" height="90.000000pt" viewBox="0 0 90.000000 90.000000"
                preserveAspectRatio="xMidYMid meet">

                <g transform="translate(0.000000,90.000000) scale(0.100000,-0.100000)"
                fill="#fff" stroke="none">
                <path d="M80 730 c-19 -19 -20 -33 -20 -280 0 -247 1 -261 20 -280 19 -19 33
                -20 370 -20 337 0 351 1 370 20 19 19 20 33 20 280 0 247 -1 261 -20 280 -19
                19 -33 20 -370 20 -337 0 -351 -1 -370 -20z m247 -265 c18 -54 35 -107 39
                -117 4 -15 0 -18 -26 -18 -23 0 -33 6 -40 25 -8 21 -16 25 -50 25 -34 0 -41
                -4 -46 -25 -5 -19 -13 -25 -35 -25 -16 0 -29 1 -29 3 0 3 66 200 75 225 3 9
                18 12 42 10 l36 -3 34 -100z m258 80 c25 -24 32 -65 19 -100 -9 -22 -57 -45
                -94 -45 -28 0 -30 -3 -30 -35 0 -32 -2 -35 -30 -35 l-30 0 0 120 0 120 70 0
                c62 0 74 -3 95 -25z m165 -95 l0 -120 -30 0 -30 0 0 120 0 120 30 0 30 0 0
                -120z"/>
                <path d="M247 503 c-2 -4 -8 -23 -12 -40 -6 -30 -5 -33 18 -33 24 0 25 1 15
                38 -12 42 -14 47 -21 35z"/>
                <path d="M480 485 c0 -35 1 -36 33 -33 28 3 32 6 32 33 0 27 -4 30 -32 33 -32
                3 -33 2 -33 -33z"/>
                </g>
            </svg>
        )
    }
    code = () => {
        return (
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                width="90.000000pt" height="90.000000pt" viewBox="0 0 90.000000 90.000000"
                preserveAspectRatio="xMidYMid meet">

                <g transform="translate(0.000000,90.000000) scale(0.100000,-0.100000)"
                fill="#fff" stroke="none">
                <path d="M80 760 c-19 -19 -20 -33 -20 -330 l0 -310 390 0 390 0 0 310 c0 297
                -1 311 -20 330 -19 19 -33 20 -370 20 -337 0 -351 -1 -370 -20z m700 -370 l0
                -210 -330 0 -330 0 0 210 0 210 330 0 330 0 0 -210z"/>
                <path d="M455 528 c-19 -55 -65 -264 -61 -275 3 -8 16 -13 28 -11 19 3 26 19
                55 137 19 74 32 140 29 148 -7 17 -45 17 -51 1z"/>
                <path d="M247 456 c-20 -31 -37 -60 -37 -66 0 -6 17 -35 37 -66 27 -41 42 -54
                57 -52 31 4 31 31 0 77 l-27 41 27 41 c31 46 31 73 0 77 -15 2 -30 -11 -57
                -52z"/>
                <path d="M577 503 c-13 -12 -7 -33 19 -73 l27 -40 -27 -41 c-31 -46 -31 -73 0
                -77 15 -2 30 11 57 52 20 31 37 60 37 66 0 6 -16 35 -36 65 -34 50 -59 66 -77
                48z"/>
                </g>
            </svg>
        )
    }
    mobile = () => {
        return(
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="90.000000pt" height="90.000000pt" viewBox="0 0 90.000000 90.000000"
            preserveAspectRatio="xMidYMid meet">
    
            <g transform="translate(0.000000,90.000000) scale(0.100000,-0.100000)"
            fill="#fff" stroke="none">
            <path d="M230 850 c-19 -19 -20 -33 -20 -400 0 -367 1 -381 20 -400 19 -19 33
            -20 220 -20 187 0 201 1 220 20 19 19 20 33 20 400 0 367 -1 381 -20 400 -19
            19 -33 20 -220 20 -187 0 -201 -1 -220 -20z m400 -370 l0 -300 -180 0 -180 0
            0 300 0 300 180 0 180 0 0 -300z m-155 -360 c0 -18 -6 -26 -23 -28 -13 -2 -25
            3 -28 12 -10 26 4 48 28 44 17 -2 23 -10 23 -28z"/>
            <path d="M475 500 l-50 -39 -26 25 c-30 29 -62 29 -67 0 -4 -20 63 -96 85 -96
            7 0 45 24 84 53 51 38 69 57 67 72 -5 33 -40 27 -93 -15z"/>
            </g>
            </svg>
    
        )
    }
    unique = () => {
        return (
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                width="90.000000pt" height="90.000000pt" viewBox="0 0 90.000000 90.000000"
                preserveAspectRatio="xMidYMid meet">

                <g transform="translate(0.000000,90.000000) scale(0.100000,-0.100000)"
                fill="#fff" stroke="none">
                <path d="M409 789 c-14 -14 -19 -29 -16 -46 7 -36 -260 -520 -297 -540 -14 -7
                -28 -24 -32 -37 -13 -54 43 -93 91 -62 37 24 553 24 590 0 48 -31 104 8 91 62
                -4 13 -18 30 -32 37 -37 20 -304 504 -297 540 6 30 -26 67 -57 67 -11 0 -29
                -9 -41 -21z m-6 -398 c-14 -29 -39 -51 -101 -91 -46 -29 -85 -51 -87 -49 -2 2
                42 87 98 189 l102 185 3 -98 c2 -79 -1 -107 -15 -136z m187 42 c54 -98 97
                -179 95 -181 -2 -2 -41 20 -87 49 -107 68 -118 89 -118 224 0 54 3 96 6 92 3
                -3 50 -86 104 -184z m-9 -192 l94 -61 -225 0 -225 0 97 63 c124 79 134 79 259
                -2z"/>
                </g>
                </svg>

        )
    }
}