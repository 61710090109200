import axios from 'axios'


export default class ApiRequest{
    constructor(params){
        this.url = params.url; this.token = params.token; this.method = params.method || 'GET';
        this.headers = {
            "Authorization": `Bearer ${this.token}`,
            "Accept": "application/json"
        }; 
        this.api = params.api || 'global'
    }
    GetNews = async () => {
   
            return await axios.get(this.url, {headers: this.headers})
            .then( response =>{ return response.data} )        
            .catch( err => { console.log(err); return false })   

    }
}