import { Children } from "react";


export function IterateChildren (options){
    const children = options.children;
    const searchString = options.searchString || 'Elliot Page';
    const list = []

    Children.forEach(children,(child, index) => {
        let query = `${child.props.text || ''} ${child.props.title || ''}${child.props.author || ''} ${child.props.source || ''} `
        if (query.toLowerCase().includes( searchString.toLowerCase() )){
            list.push( child )
        }
        
    })
    return list
}