import { React } from  'react';
import '../Styles/SocialBtnV2.css'


export default function SocialButtons(){


    return(
        <div className="v2-social-buttons">
            <div className="v2-social-button">
                <SocialGitHub />
            </div>
            <div className="v2-social-button">
                <SocialTwitter />
            </div>
            <div className="v2-social-button">
                <SocialLinkedIn />
            </div>
        </div>
    )
}

const SocialGitHub = () => {
    
    return (
        <a aria-label="Link-GitHub" href="https://github.com/calipsow" target="_blank"  rel="noreferrer">
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                width="20.000000pt" height="20.000000pt" viewBox="0 0 90.000000 90.000000"
                preserveAspectRatio="xMidYMid meet">
                <g transform="translate(0.000000,90.000000) scale(0.100000,-0.100000)"
                fill="#fff" stroke="none">
                <path d="M355 796 c-107 -34 -186 -102 -232 -201 -24 -51 -28 -73 -28 -145 0
                -72 4 -94 28 -145 43 -92 115 -158 212 -195 24 -9 25 -8 25 30 0 39 -1 40 -33
                40 -42 0 -57 13 -90 72 -30 55 -22 60 26 18 47 -40 93 -40 125 1 22 27 22 29
                5 35 -64 19 -108 42 -125 65 -29 39 -34 99 -12 145 13 26 18 55 16 86 -5 60
                13 70 72 42 55 -27 194 -26 248 1 54 27 70 16 67 -47 -2 -36 3 -64 15 -87 22
                -41 17 -102 -12 -140 -17 -23 -60 -45 -126 -66 -18 -5 -18 -7 5 -31 19 -20 24
                -38 27 -94 l4 -69 47 24 c63 32 124 98 158 170 24 51 28 73 28 145 0 72 -4 94
                -28 145 -30 65 -81 124 -138 160 -78 50 -199 68 -284 41z"/>
                </g>
            </svg>              
        </a>
    )
}
const SocialTwitter = () => {
    return (
        <a aria-label="Link-Twitter" href="https://twitter.com/me_Calypso" target="_blank"  rel="noreferrer">                
        <svg fill="#ffffff" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 30 30" width="20.000000pt" height="20.000000pt">
        <path d="M28,6.937c-0.957,0.425-1.985,0.711-3.064,0.84c1.102-0.66,1.947-1.705,2.345-2.951c-1.03,0.611-2.172,1.055-3.388,1.295 c-0.973-1.037-2.359-1.685-3.893-1.685c-2.946,0-5.334,2.389-5.334,5.334c0,0.418,0.048,0.826,0.138,1.215 c-4.433-0.222-8.363-2.346-10.995-5.574C3.351,6.199,3.088,7.115,3.088,8.094c0,1.85,0.941,3.483,2.372,4.439 c-0.874-0.028-1.697-0.268-2.416-0.667c0,0.023,0,0.044,0,0.067c0,2.585,1.838,4.741,4.279,5.23 c-0.447,0.122-0.919,0.187-1.406,0.187c-0.343,0-0.678-0.034-1.003-0.095c0.679,2.119,2.649,3.662,4.983,3.705 c-1.825,1.431-4.125,2.284-6.625,2.284c-0.43,0-0.855-0.025-1.273-0.075c2.361,1.513,5.164,2.396,8.177,2.396 c9.812,0,15.176-8.128,15.176-15.177c0-0.231-0.005-0.461-0.015-0.69C26.38,8.945,27.285,8.006,28,6.937z"/></svg>                        
        </a> 
    )
}

const SocialLinkedIn = () => {
    return(
        <a aria-label="Link-Linkedin" href="https://www.linkedin.com/in/dennis-wilke-646691243" target="_blank"  rel="noreferrer">
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                width="20.000000pt" height="20.000000pt" viewBox="0 0 30.000000 30.000000"
                preserveAspectRatio="xMidYMid meet">

                <g transform="translate(0.000000,30.000000) scale(0.100000,-0.100000)"
                fill="#fff" stroke="none">
                <path d="M44 247 c-2 -7 -3 -56 -2 -108 l3 -94 105 0 105 0 0 105 0 105 -103
                3 c-79 2 -104 0 -108 -11z m66 -42 c0 -8 -7 -15 -15 -15 -8 0 -15 7 -15 15 0
                8 7 15 15 15 8 0 15 -7 15 -15z m98 -37 c18 -18 16 -88 -3 -88 -10 0 -15 11
                -15 35 0 24 -5 35 -15 35 -10 0 -15 -11 -15 -35 0 -24 -5 -35 -15 -35 -11 0
                -15 12 -15 44 0 38 3 45 23 49 39 8 43 7 55 -5z m-98 -43 c0 -33 -4 -45 -15
                -45 -11 0 -15 12 -15 45 0 33 4 45 15 45 11 0 15 -12 15 -45z"/>
                </g>
                </svg>
        </a>
    )
}