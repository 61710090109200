import {React} from 'react';
import './CSS/NavCSS.css';
import { Link } from 'react-router-dom'

export default function NavigationComponent(){
    let size =  '26.000000pt' 

    return(
        <>
        <input tabIndex={0} aria-label="Menu-Button" type="checkbox" id="active" className={"input-button-menuv2" } ></input>
                <label htmlFor="active" className={ "menu-btn mobile"}>
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                width={size} height={size} 
                viewBox="0 0 50.000000 50.000000"
                preserveAspectRatio="xMidYMid meet">

                <g transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)"
                fill="#fff" stroke="none">
                <path d="M0 420 l0 -43 250 0 250 0 0 20 0 30 -250 0 -250 0 0 -30z"/>
                <path d="M0 250 l0 -30 250 0 250 0 0 20 0 30 -250 0 -250 0 0 -30z"/>
                <path d="M0 90 l0 -19 250 0 250 0 0 20 0 30 -250 0 -250 0 0 -30z"/>
                </g>
                </svg>


                </label>
            <div className="wrapper">
            <ul>
            <li><Link tabIndex={1} aria-label="Homepage-Link" to="/">Home</Link></li>
            <li><Link tabIndex={2} aria-label="About-Link"to="/blog">About</Link></li>
            <li><Link tabIndex={3} aria-label="Produkt-Link" to="/products">Services</Link></li>
            <li><Link tabIndex={4} aria-label="Newsletter-Link" to="/realtime-news">Newsletter</Link></li>
            <li><Link tabIndex={5} aria-label="Kontakformular-Link" to="/contact">Kontakt</Link></li>
            </ul>
            </div>

        </>
    )
}