import { React, useState, useEffect } from "react"
import '../Styles/AppV2.css'

export default function BackgroundWaves(){
    return <Background />
}

const Background = () => {

    
    return(
        <>            
        <div className="V2-Background">                
            <Waves />
        </div>
        </>
    )
  }
  
  const Waves = () => {
    const [wavesWidth, setWavesWidth] = useState(window.innerWidth)
    
    useEffect(() => {
        function handleResize(){
            setWavesWidth(window.innerWidth);
        }
        window.addEventListener("resize", handleResize)
    
        return () => {
            window.removeEventListener("resize", handleResize)
        }
    })

    
    return(
        <div className="V2-svg-waves" id="V2-svg-waves">

            <img 
            alt=""
            src="/waves.svg" 
            width={wavesWidth} 
            height={550}
            ></img>
        </div>
    )
  }