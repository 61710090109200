import React from 'react';
import './index.css';
import Welcome from './App';
import ContactComp from './Components/ContactComponent/ContactComp';
import { createRoot } from 'react-dom/client';
import {BrowserRouter as Router ,Routes, Route} from 'react-router-dom';
import SkillMaintain from './Components/SkillPage/SkillMainPage';
import NewsPage from './Components/Pages/NewsPage/NewsPage';
import ProductPage from './Components/Pages/Produkts/Products';
import DSGVO from './Components/DSGVO/DSGVO';
import ImpressumMain from './Components/Impressum/ImpressumMain';
import ErrorPage from './Components/Error/ErrorPage';
import Authaticate from './Middleware/authenticate';
import Application from './AppV2/AppV2';

(async function ( token ){
  
  
  if( !!token && token !== 'inital' ) return; 

  var user_token = await Authaticate({type: 'user'})  
  
  .catch( error => { console.warn(error);} )


  if(typeof user_token === 'boolean'){
    localStorage.setItem('user_token', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImVtYWlsQGVtYWlsLmNvbSIsInBhc3MiOiJlbWFpbEBlbWFpbC5jb21lbWFpbEBlbWFpbC5jb21lbWFpbEBlbWFpbC5jb21lbWFpbEBlbWFpbC5jb20iLCJpYXQiOjE2NTc4ODg2NTcsImV4cCI6MTY1ODE0Nzg1N30.YIKnF-1htsvZHajQnPEzIdlo_opXpZt3XbQOuGgaPOI' )
    return;
  
  } else localStorage.setItem('user_token', user_token.token )

})( localStorage.getItem('user_token' ) || undefined )

const rootElement = document.getElementById('root');
  const Root = createRoot(rootElement)
  Root.render(     
    <React.StrictMode>
      <Router>
        <Routes>
        <Route index element={ <Welcome />} />
        <Route path="contact" element={ <ContactComp viewHeight={true} /> }/>
        <Route path="blog" element={ <SkillMaintain /> }/>
        <Route path="products" element={ <ProductPage />} />
        <Route path="realtime-news" element={ <NewsPage /> } />
        <Route path="datenschutz" element={ <DSGVO /> } />
        <Route path="impressum" element={ <ImpressumMain /> } />
        <Route path='*' element={ <ErrorPage/> } /> 
        { process.env.NODE_ENV === 'development' ? <Route path='version-2' element={ <Application />} /> : null } 

        
        </Routes>
      </Router>
    </React.StrictMode>    
  );
