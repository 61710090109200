import React from 'react';
import './loading.css';

export default class LoadingScreen extends React.PureComponent{


    render() {
         
        return(
        <>
        {        
            !this.props.smallLoader ?
            <div id="load">
                        <div>G</div>
                        <div>N</div>
                        <div>I</div>
                        <div>D</div>
                        <div>A</div>
                        <div>O</div>
                        <div>L</div>
                    </div>
            :
            <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div> 
        }        
        </>
                    
        )
    }
}
            