import {React, useState, Component} from 'react';
import HeaderV2 from './CompsV2/HeaderV2'
import SocialButtons from './CompsV2/SocialBtnV2';
import './Styles/AppV2.css'


let boxElement;
let prevRatio = 0.0;


export default class Application extends Component {
    constructor(props){
        super(props);
        this.defaultBackgroundColor = 'var(--default-bg)'
    }
    componentDidMount(){
        let b = document.querySelector('body'); 
        let h = document.querySelector('html')
        b.style.backgroundColor = 'var(--App-V2-color-blue-0)';
        h.style.backgroundColor = 'var(--App-V2-color-blue-0)';
        //? Initialize the Observer 
        let observedElems = document.querySelectorAll('.v2-observing')
        for(var el of observedElems){
            boxElement = el
            createObserver()
        }
    }
    componentWillUnmount(){
        let h = document.querySelector('html')
        let b = document.querySelector('body')                 
        h.style.backgroundColor = this.defaultBackgroundColor;
        b.style.backgroundColor = this.defaultBackgroundColor;
    }

    render(){
       return(
            <div>
                <HeaderV2 />
                <section>
                    <SocialButtons />
                    <Background />
                    <LandingPageContent />
                </section>
            </div>
       ) 
    }
}

const Background = () => {

    return(
        <>            
        <div className="V2-Background">                
            <Waves />
        </div>
        </>
    )
}

const Waves = () => {
    const [wavesWidth, setWavesWidth] = useState(window.innerWidth)

    const handleResizeWaves = (width) => {
        setTimeout(()=>{
            setWavesWidth(width)
        }, 250) // Prevent to much setState 
    }

    return(
        <div className="V2-svg-waves" id="V2-svg-waves" 
        onResize={ () => handleResizeWaves(window.innerWidth) }>
            <img 
            alt=""
            src="/waves.svg" 
            width={wavesWidth} 
            height={550}
            ></img>
        </div>
    )
}

const LandingPageContent = () => {

    return (

        <div className="v2-landing-content" id="v2-landing-content">

            <div className="v2-first-landing-page-content v2-observing" id="v2-first-landing-page-content">            
                <h1>Websites und Apps von Profis erstellen lassen<hr/> </h1>
                <p>
                    Lassen Sie Ihre Vision von dem passenden Partner erstellen.
                </p>
                <p>
                    Wir erstellen Websites und Apps, nach alter Schule was bedeutet, 
                    dass wir nicht einfach Layouts zu einer Website zusammenschieben.
                    <hr/> 
                    <strong>
                        Calypso Software
                    </strong>
                </p>
            </div>

            <div className="v2-first-landing-page-content v2-observing v2-container" id="v2-first-landing-page-content">            
                <h2>Web und App Design<hr/> </h2>
                <p>
                    Ein gutes Design ist einer der wichtigsten Aspekte von Websites und Apps.  
                </p>
                <p>
                    <hr/>
                    Im Jahr 2015 entfielen fast 60% aller Seitenaufrufe auf mobile Endgeräte.                    
                </p>
                <p>
                    Lassen Sie Ihre Website oder App unabhängig von vorgegeben Layouts erstellen
                    und profitieren Sie mit einem einzigartigem Design.
                <hr/>
                    <strong>
                        Einzigartigkeit
                    </strong> 
                </p>
            </div>

            <div className="v2-first-landing-page-content v2-observing v2-container" id="v2-first-landing-page-content">            
                <h2>SEO Optimierung<hr/> </h2>
                <p>
                Damit Ihre Website gefunden werden kann, optimieren wir diese für alle Suchmaschinen.
                <hr/>
                Diese Optimierung kostet kein zusätzliches Geld, sondern ist ein selbstverständlicher inkludierter 
                Bestandteil
                eines jeden Web-Produktes.
                </p>               
                <p>
                Auch für breits bestehende Websites bieten wir die SEO Optimierung an.
                <hr/>
                <strong>
                    Sichtbarkeit
                </strong> 
                </p>
            </div>

            <div className="v2-first-landing-page-content v2-observing v2-container" id="v2-first-landing-page-content">            
                <h2>Mehr als Wordpress<hr/> </h2>
                <p>
                    Unser Ansatz besteht darin uns von den sehr begrenzten Möglichkeiten 
                    von Wordpress uvm., zu befreien. 
                    <br/>
                    Damit wir alle Aspekte der Webentwicklung für Sie einsetzen 
                    können.      
                <hr/>
                    Selbstverständlich erstellen wir auch Serverseitige Dienste, wenn sie gebraucht werden. 
                </p>               
                <p>
                    Für einen <strong style={{fontWeight: '650'}}>pauschalen und festgesetzten Preis</strong>, entwickeln wir Ihr Projekt.  
                <hr/>
                <strong>
                    Flexibilität
                </strong> 
                </p>
            </div>

            <div className="v2-first-landing-page-content v2-observing v2-container" id="v2-first-landing-page-content">            
                <h2>Kommerzielle Anwendungen<hr/> </h2>
                <p>
                    Websites und Apps lassen sich kommerzialisieren, daher bieten wir eine kostenlose Beratung 
                    an welche Art der Monetarisierung für Ihre Anwendung oder Vision passt.                                     
                <hr/>
                    Öffentlich verfügbare Blogs, Tutorials, Informationen uvm. können beispielsweise durch Werbung, 
                    den Verkauf von Produkten usw. können als Einnahmequelle genutzt werden.
                    <hr/>     
                            
                <strong >
                    Kommerzialisierung
                </strong> 
                </p>
            </div>

        </div>
    )
}



function createObserver() {
    let observer;
  
    let options = {
      root: document,
      rootMargin: '0px',
      threshold: buildThresholdList()
    };

    observer = new IntersectionObserver(handleIntersect, options);
    observer.observe(boxElement);
}


function buildThresholdList() {
    let thresholds = [];
    let numSteps = 20;
  
    for (let i=1.0; i<=numSteps; i++) {
      let ratio = i/numSteps;
      thresholds.push(ratio);
    }
    
    thresholds.push(0);
    return thresholds;
}


//? Die Funktion dient als Callback wenn das Element im Bildschirm zu sehen ist
function handleIntersect(entries, observer) {
    entries.forEach((entry) => {
      if (entry.intersectionRatio > prevRatio) {
        
        entry.target.classList.add('v2-activated')
      } 
      
      prevRatio = entry.intersectionRatio;
    });
}