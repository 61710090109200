
/*
function createObserver() {
    let observer;
  
    let options = {
      root: null,
      rootMargin: window.innerWidth > 786 ? "-250px" : '0px',
      threshold: buildThresholdList()
    };
  
    observer = new IntersectionObserver(handleIntersect, options);
    observer.observe(boxElement);
}
function buildThresholdList() {
    let thresholds = [];
    let numSteps = 20;
  
    for (let i=1.0; i<=numSteps; i++) {
      let ratio = i/numSteps;
      thresholds.push(ratio);
    }
  
    thresholds.push(0);
    return thresholds;
}
function handleIntersect(entries, observer) {
    entries.forEach((entry) => {
      if (entry.intersectionRatio > prevRatio) {
        try{entry.target.classList.remove('zero-opacity')}catch(e){}
        entry.target.classList.add('active')
      } 
  
      prevRatio = entry.intersectionRatio;
    });
}
*/
export default class ScrollEvent{
    constructor(options){

        this.prevRatio=0.0
        let section = options.section
        this.el = document.querySelectorAll(section)            
        for(let i=0; i < this.el.length; i++){
            this.boxElement = this.el[i]
            this.createObserver(this.boxElement)
    
        }
                  
    }
    createObserver(elm) {
        
        let observer;
      
        let options = {
          root: null,
          rootMargin: window.innerWidth > 786 ? "-250px" : '0px',
          threshold: this.buildThresholdList()
        };
      
        observer = new IntersectionObserver(this.handleIntersect, options);
        observer.observe(elm);
    }
    buildThresholdList() {
        let thresholds = [];
        let numSteps = 20;
      
        for (let i=1.0; i<=numSteps; i++) {
          let ratio = i/numSteps;
          thresholds.push(ratio);
        }
      
        thresholds.push(0);
        return thresholds;
    }

    handleIntersect(entries, observer) {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > this.prevRatio) {     
            // console.log(entry.target)       
            entry.target.classList.add('active')
            entry.target.style.backgroundColor = 'rgba(255, 255, 255, 1) !important';
          } 
      
          this.prevRatio = entry.intersectionRatio;
        });
    }
}