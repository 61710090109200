import React from 'react';
import './Card.css'
import ComponentImportHandler from '../../../../import/ImportHandler';
import axios from 'axios'
import UID from '../../../../../Middleware/CreateUID';

export default class NewsCardComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = { renderArticleContent: false, loadingContent: false }
        this.handleClick = this.handleClick.bind(this)
        this.content = []
    }
    handleClick = async  (e) => {
        e.preventDefault();
        this.setState({ renderArticleContent: true, loadingContent: true })
        await this.loadingContent()
        
    }

    loadingContent = async () => {
        let url = 'https://calypso-software.eu-4.evennode.com/api/news/article'
        await axios.post(url,
            { url: this.props.articleSrc },
            { headers: {'Authorization': 'Bearer '+window.localStorage.getItem('user_token')}}
        
        )
        .then((response) => {
            let arr = response.data.message || response.data
            if(Array.isArray(arr))
            {
                arr.forEach( item => {
                    this.content.push(
                        <ContentLoader 
                        __key={UID()}
                        key={UID()}
                        paragraph={item}
                        />
                    )
                })
                this.setState({loadingContent: false})
            }  
            

        })
        .catch(error => { console.log(error) })
        
    }




    render(){
        try{
            this.props.content = this.props.content.split('… [+')[0]
        }catch(e){
            
        }
        return(
        <>           
        <div className="wide-article-link" key={this.props.__key} id={this.props.__id}>
            <div className="row ">
                <div className="custom-columns" >
 
                <h4 style={{fontFamily: 'Helvetica Neue', fontSize: '1.5rem'}} className="article-title">
                    <a 
                    aria-label={'Artikel-Link'}
                    className="anchor-link" 
                    style={{textDecoration: 'none'}} 
                    target="_blank" rel="noreferrer" 
                    href={ !this.props.articleSrc ? '#' : this.props.articleSrc } >
                        
                    {this.props.title}
                    </a>
                </h4>
                <br/>
                

                {
                    !!this.props.pictureSrc ?
                    <> 
                    <br/>
                        <div 
                        className="small-12 medium-3 columns flex-container align-middle" 
                        style={{width: '100%', height: '100%'}}>
                         <img 
                         aria-label={this.props.title}
                         style={{borderRadius: '2px'}} alt={this.props.title}  
                         layout='fill' objectfit='contain' src={this.props.pictureSrc  }/>
                        </div>
                    <br/>
                    <br/>
                    </>
                    :<></>
                }   

                    <div className="article-elipsis">
                    { 

                        <>
                            <p aria-label={'Artikel Text'} className='paragraph-text' style={ {color: 'white', fontFamily: 'sans-serif'}}>
                                {this.props.text.includes('<img') || this.props.text.includes('class=')|| this.props.text.includes('src=') 
                                ? 'sorry there is an error with this source..' : this.props.text }</p>
                            <hr/>                            
                        </>  
                    }
                    </div>
                {
                !!this.props.author || !!this.props.source ?
                <p aria-label={'Author des Artikels ist '+ this.props.author || this.props.source } className="article-author"><em>by <span  >{this.props.author || this.props.source}</span></em></p>
                : <></>
                }


    


                    <div className="article-elipsis">
                        {this.props.publishedAt.split('T')[0]}
                        <hr/>

                        {
                            !this.state.renderArticleContent 

                             
                            ? (!!this.props.articleSrc && 
                                this.props.articleSrc.includes('https://finance.yahoo.com') )
                                || this.props.articleSrc.includes('https://de.nachrichten.yahoo.com') 
                                || this.props.articleSrc.includes('https://www.tagesschau.de') 
                                    
                                    ? <a  rel="noreferrer" target="_blank" href={this.props.articleSrc} onClick={ e => this.handleClick(e) } className="read-more">
                                          <button aria-label="Artikel Weiterlesen" className="custom-btn btn-3"><span>Read More</span></button>
                                    </a>

                                    : <a aria-label="Dem Link zur Betreiberseite folgen" className="custom-btn btn-3" rel="noreferrer" target="_blank" href={ this.props.articleSrc || '#' }>
                                            <span>
                                            {!!this.props.author ? "Go to " +  this.props.author :  "Go to " +  this.props.source }
                                            </span>
                                        </a>
                            
                            : this.state.loadingContent 
                            
                            ? 
                            <>{ new ComponentImportHandler().LoadScreen(true) }</>
                            
                            : 
                            <>
                                { this.content }
                            </>
                          
                        }
                        

                    </div>
                        
                   
                </div>


            </div>
        </div> 

        </>
        ) 
    }
}

class ContentLoader extends React.Component {

    render(){
        return (
            <p key={this.props.__key} className='paragraph-text' style={ {color: 'white', fontFamily: 'sans-serif'}}>{this.props.paragraph}</p>
        )
    }
}