import React from 'react';
import './Contact.scss';
import MenuComp from  '../MenuComp/MenuComp';
import Security from './Security/Security';
import NavigationComponent from '../SkillPage/comp/NavMenu';
import { Link } from 'react-router-dom'
import ComponentImportHandler from '../import/ImportHandler';
import CookieHandler from '../../Middleware/CookieHandler';
import ReCAPTCHA from "react-google-recaptcha";

export default class ContactComp extends React.Component {
    constructor(props) 
    { super(props); this.secure = new Security(); 
        this.CookieHandler = new CookieHandler(); 
        this.state={ renderForm: true }; 
        this.handleFormSubmit = this.handleFormSubmit.bind(this);  
        document.querySelectorAll('html')[0].setAttribute('lang', 'de')
        this.style_default = { 
            fontSize: '1.2rem', fontWeight: 'normal', fontFamily: 'Arial, Helvetica, sans-serif', color: 'white' 
        }

    }

    handleFormSubmit(handle){
        this.setState({ renderForm: false })
    }

    componentDidMount() {
        if(this.props.viewHeight){
            window.scrollTo(0,0); 
            document.querySelectorAll('title')[0].textContent='Kontakt | Calypso Software'
        }
        this.cookieCheck = this.CookieHandler.proofCookieValues('form_control','false');
        if( !this.cookieCheck.existing && !this.cookieCheck.value ){
            this.setState({ renderForm: false })
        }
    }

    render() {               
        return(
            <div id="container-contact-hydrateRoot">
            { this.props.viewHeight ? <NavigationComponent relative={true} /> : <></>}
            { this.props.viewHeight ? new ComponentImportHandler().Logo() : <></> } 
            <div aria-label="Contact-Container" 
            id="contact-container-id"  
            className={ this.props.viewHeight ? "Contact-Container view-height" : "Contact-Container" } >
           { this.props.viewHeight ? <MenuComp /> : <></> }
            
            { this.state.renderForm ? <FormSection handleSubmit={this.handleFormSubmit} renderCookieBanner={this.props.viewHeight} /> 
              
            : 
                
                <div className="success-main-continer" >
                    <div className="success_container">
                        <div className="SVG_Connect_Container">
                        <svg  x="0px" y="0px"
                            width="120" height="120"
                            viewBox="0 0 30 30"                            
                            fill="#fff">
                            <path d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M21.707,12.707l-7.56,7.56 c-0.188,0.188-0.442,0.293-0.707,0.293s-0.52-0.105-0.707-0.293l-3.453-3.453c-0.391-0.391-0.391-1.023,0-1.414s1.023-0.391,1.414,0 l2.746,2.746l6.853-6.853c0.391-0.391,1.023-0.391,1.414,0S22.098,12.316,21.707,12.707z">
                        </path>
                        </svg><br/>
                        <p style={this.style_default}> 
                            Vielen Dank für Ihre Anfrage, <br/>
                            innerhalb der nächsten Tage werden wir uns mit Ihnen in Verbindung setzen. <br/>
                            <strong>Wir freuen uns auf Sie.</strong>
                        </p>
                        {
                         this.props.viewHeight ?
                        <Link aria-label="Homepage-Link" to="/" state={this.style_default}>
                            <button aria-label="Homepage-Button" className="Input-Standart b unique-dis" style={{color: "rgb(255,80,35)" }} >Home Page</button>
                        </Link>
                        : <></>
                        }
                        <br />
                        <br/>

                        </div>
                        <br/>


                        
                    </div>
                    </div>
                
            }           
                        
            </div>
           
            </div>
        ); 

    }
}


class FormSection extends React.Component {
    constructor(props) {
        super(props);
        this.siteKey='6LcCbkEgAAAAAIMFw6y3w1RK6jO3n3bzXGvrKceD';

        this.secure = new Security();
        this.state = { 
            name: {valid: false, value: ''}, 
            mail: {valid: false, value: ''}, 
            phone: {valid: false, value: ''},
            text: {valid: false, value: ''}, 
            render: true, 
            loading: false, 
            response: null, 
            infoBox: false, 

            callback: "not fired",
            value: null,
            load: false,
            expired: false 
        }
        this._reCaptchaRef = React.createRef();

        this.handleNameEvent = this.handleNameEvent.bind(this)
        this.handleEmailEvent = this.handleEmailEvent.bind(this)
        this.handleTextEvent = this.handleTextEvent.bind(this)
        this.handlePhoneEvent = this.handlePhoneEvent.bind(this)

        this.handleFormSubmit = this.handleFormSubmit.bind(this)
        document.cookie="form_control=false;path=/"
    }
    
    handleCaptchaChange= value => {
        this.setState({ value: value });
        if (value === null) this.setState({ expired: true });
    }
    asyncScriptOnLoad = () => {
        this.setState({ callback: "called!" });
    };
    handleFormSubmit = sub => {
        this.props.handleSubmit(sub)
    }
    async handleEvent( e ){
        e.preventDefault();
        
        if( this.state.name.valid && this.state.mail.valid && this.state.text.valid && this.state.phone.valid ){
            var data = { 
                'name':this.state.name.value, 
                'mail': this.state.mail.value, 
                'phone': this.state.phone.value,
                'message': this.state.text.value 
            }
            if( this.state.value === null ){ return }

            this.setState({render: false, loading: true})
            var x = await this.secure.contactFormSecurity() 

            if( x ){
            
                var result = await this.secure.requestForm( data );
                var formRes = await this.secure.sendFormRequest(); 

                if(!Array.isArray(result)){
                    if( typeof formRes === 'boolean' || formRes === 401 ) 
                    { this.setState({ loading: result, response: false })  }
                    
                    else if( formRes >= 200 && formRes <= 299 )
                    { 
                        
                        this.setState({ loading: false, response: true })   
                        this.handleFormSubmit(true)                     
                    }
                    
                }else{  
                    this.setState({ loading: false, response: false})
                    // failed to fetch
                }
            } else {
                // Security-Check failed go on..
                this.setState({response: false, loading: false})
                // console.log('Security-Check failed!')
            }
        }else{  
            this.setState({ infoBox: true })
        }
    }
    handlePhoneEvent(e){
        e.preventDefault()

        if( !isNaN(e.target.value) && e.target.value.length >= 5 ){

            if(this.state.phone.value !== parseInt(e.target.value) || !this.state.phone.valid ){
                this.setState({ phone: {valid: true, value: parseInt(e.target.value) } })
                return;
            }            
        }
        	
        
    }
    handleNameEvent( name ){
        
         this.setState({ name: { valid: name.valid, value: name.value } })      
    }
    handleEmailEvent( mail ){        
        
         this.setState({ mail: { valid: mail.valid, value: mail.value } })    
    }
    handleTextEvent( text ){
        
         this.setState({ text: { valid: text.valid, value: text.value } })    

    }

    infoBox(){
        return (
            <div className="info-box">
                <p> Bitte füllen sie das Kontaktformular vollständig aus </p>
            </div> 
        )   
    }

    render(){

        if( !this.state.render ){
            if( this.state.loading ){return new ComponentImportHandler().LoadScreen(true) }
            else
            { 
                if( !this.state.response ){ return <SendUnauthorized message={'Sorry please try it later again'} />}else{ return <></> }
            }             
        
        }else{
            return(
                <>
                <div className="success-container">
                    <form aria-label="Kontaktformular" className={ this.props.renderCookieBanner ? "form form__opacity" : 'form form__opacity'} method='post' action='/' target="_self" onSubmit={ e => this.handleEvent(e) }>
                        <h2 style={{fontSize: '1.4rem', color: 'white'}}>
                            Kontaktformular
                        </h2><br/> 
                        <NameHandler NameTextHandler={ this.handleNameEvent } />
                        <EmailValidator validateEmailInput={ this.handleEmailEvent } /> 
                        <input tabIndex={3} aria-label="Eingabefeld-Mobile" type="tel" id="phone" name="phone" placeholder="0123-999999" required  
                        className="Input-Standart Text-Input"
                        onChange={ e => this.handlePhoneEvent(e) }
                        />
                        <MessageEventHandler MessageEventText={ this.handleTextEvent } />
                        <a href={".success-container"} >
                            <button type = "submit"                         
                            className="Input-Standart b unique-sub" id="submit">Submit</button>
                        </a>
                        {                        
                        this.props.renderCookieBanner ?
                            <Link to="/" >
                                <button className="Input-Standart b unique-dis" style={{color: "rgb(255,80,35)" }} >Zurück</button>
                            </Link>
                        : 
                        <></>
                        } <br/>

                        {
                            this.state.infoBox ?
                            
                            this.infoBox() 
                            : 
                            <></> 
                        }
                         <br/>
                         <div className="ReCAPTCHA-container">
                            <ReCAPTCHA
                                    style={{ display: "inline-block" }}
                                    theme="dark"
                                    ref={this._reCaptchaRef}
                                    sitekey={this.siteKey}
                                    onChange={this.handleCaptchaChange}
                                    asyncScriptOnLoad={this.asyncScriptOnLoad}
                                />
                         </div>
                         <br/>
                        <div className="Info-DSGVO-continer">
                            <p className={ !this.props.renderCookieBanner ? "text-paragraph" : "text-paragraph" }>
                                Ihre Daten werden gemäß der DSGVO verarbeitet, 
                                nähere Informationen dazu finden Sie in der<Link style={{textDecorationLine: 'underline',textUnderlineOffset: '2px'}} to="/datenschutz"> Datenschutzerklärung</Link> unter Terms.
                            </p>
                        </div>
                    </form>

                        
                    </div>
                </>
            )
        }

    }
}



class SendUnauthorized extends React.Component {
    render() { 
        return(
            <div className="request-failed request" >
                <Paragraph text={ this.props.message } _style={'default-font'} />                 
            </div>
        ) 
    }
}

class Paragraph extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            text: this.props.text,
            style: this.props._style === undefined ? 'default-font' : this.props._style
        };
    }
    render() {
        return (            
            <p className={ this.state.style }>
                { this.state.text }
            </p>
        )
    }
}


class EmailValidator extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.state = { valid: false }

    }
    checkEmail( e ){
        const regExAt = /@/, regExDot = /\./,chars = /[a-z]/;
        this.emailElement = e.target;

        if( regExAt.test( e.target.value ) && regExDot.test( e.target.value ) && chars.test( e.target.value ) ){

            this.emailElement.style.color = "rgb(45,180,70)";
            return true;

        }else if(!regExAt.test( e.target.value ) || !regExDot.test( e.target.value ) || !chars.test( e.target.value )){

            this.emailElement.style.color= "rgb(234,58,56)";
            return false;
        }                                      
    }


    handleChange(e){

        this.props.validateEmailInput( { valid: this.checkEmail(e), value: e.target.value } );        
    }
    render(){
        return(           
            <section>
                <input tabIndex={2} aria-label="Eingabefeld-Email" onChange={(e)=>this.handleChange(e)} type="email" id="Email" name="Email" placeholder="Email" className="Input-Standart Text-Input" /><br/>
            </section>         
        ); 
    }
}
class NameHandler extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { valid: false }
        this.handleChange = this.handleChange.bind(this);
    }

    checkValid( e ){
        this.nameElement = e.target;         
        var check = e.target.value.split('');        
        var x = check.filter( char => char !== ' ' && char !== '\n' && char !== '' )
        if( x.length >= 3 ){
            
            return true;
        }else{  return false; }             
    }

    handleChange(e){ this.props.NameTextHandler({ valid: this.checkValid(e), value: e.target.value } ) }
    render(){
        return(
            <section>
                <input tabIndex={1} aria-label="Eingabefeld-Name" onChange={(e)=>this.handleChange(e)} type="text" id="Name" name="Name" placeholder="Name" className="Input-Standart Text-Input" />
            </section>
        );
    }
}
class MessageEventHandler extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { valid: false }
        this.handleEvent = this.handleEvent.bind(this)
    }
    textValid( e ){
        this.messageElement = e.target; 
        this.messageElement.style.color ="rgb(45,180,70)";
        var check = e.target.value.split('');        
        var x = check.filter( char => char !== ' ' && char !== '\n' && char !== '' )
        if( x.length > 0 ){
            return true;
        }else{ return false; }                  
    }
    handleEvent( e ) {
        this.props.MessageEventText({ valid: this.textValid( e ), value: e.target.value })
    }
    render(){
        return(
            <section>
                <textarea tabIndex={4} aria-label="Eingabefeld-Nachricht" onChange={(e)=>this.handleEvent(e)} id="Discrption" name="Discrption" className="Input-Standart Text-Input" placeholder="Beschreibe Sie Ihre Vorstellungen.." />
            </section>
        );
    }
}
