import React from 'react';
import Content from './Content';
import './SCSS/Styles.scss';


export default class MobContentContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            content: [], 
            failed: false
        }
        this.content = []
        this.elements = []
        this.height = 0
        this.firstRender = true;    
        window.scrollTo(0, 0)   
    }
    async componentDidMount(){
        if(!this.firstRender) return; 
        var arr = [];
        this.content = this.props.content
        arr = this.content.index.map( _content => _content )
        
        arr.forEach( content => { 
            this.elements.push( 
                this.createContent(content.sizes, content.title, content.thumbnail, content.text, this.getUniqueID()+'-'+new Date().getTime(), this.getUniqueID()+'-'+new Date().getTime()+'-' )
            )
        })
        this.firstRender = false
        this.setState({content: this.elements })
    }

    createContent = (sizes, title, thumbnail, text, __key, key) => {

        return <Content key={key} __key={__key} sizes={sizes} thumbnail={thumbnail} title={title} text={text} />
    }
    GenerateUIDLog = async () => {     
        return this.getUniqueID();
                 
    }
    
    getUniqueID = () => {        
        const s4 = () => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
        return s4() + s4() + '-' + s4();
    };
    

    render()
    {
        

        return(
            
            <div className="row medium-8 large-7 content-container" key={this.getUniqueID()}>
                
                { !this.state.content[0] ? <></> : this.state.content  }
            
            </div>
            
            
        )
    }
}

