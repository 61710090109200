import {React, Component} from 'react';
import './Welcome.scss';
import './Welcome.css';
import './Glitch.css';
import MenuComp from './Components/MenuComp/MenuComp';
import CookieHandler from './Middleware/CookieHandler'; 
import Security from './Components/ContactComponent/Security/Security';
import NavigationComponent from './Components/SkillPage/comp/NavMenu';
import Footer from './Components/Footer/Footer';
import ComponentImportHandler from './Components/import/ImportHandler';
import './AppV2/Styles/AppV2.css';
import Column from './Components/App/Column';
// ? import ApiRequest from './Middleware/apiRequest';

export default class Welcome extends Component {
  constructor(props){
    super(props);
    window.scrollTo(0, 0);
    this.news = {global: undefined, stocks: undefined}
    this.state={ new: true, status: null, loading: false }    
    this.options = {
      fullScreen: {
        enable: true,
        zIndex: 0
      },
      particles: {
        number: {
          value: 200,
          limit: 300,
          density: {
            enable: true,
            value_area: 800
          }
        },
        color: {
          value: "#ffffff"
        },
        shape: {
          type: "circle",
          stroke: {
            width: 0,
            color: "#000000"
          },
          polygon: {
            nb_sides: 2
          },
          image: {
            src: "images/github.svg",
            width: 100,
            height: 100
          }
        },
        opacity: {
          value: 0.1,
          random: true,
          anim: {
            enable: true,
            speed: 1,
            opacity_min: 0.5,
            sync: false
          }
        },
        size: {
          value: 18,
          random: true,
          anim: {
            enable: true,
            speed: 8,
            size_min: 10,
            sync: false
          }
        },
        line_linked: {
          enable: true,
          distance: 90,
          color: "#ffffff",
          opacity: 1,
          width: 1
        },
        move: {
          enable: true,
          speed: .12,
          direction: "none",
          random: false,
          straight: false,
          out_mode: "out",
          bounce: false,
          attract: {
            enable: false,
            rotateX: 600,
            rotateY: 1200
          }
        }
      },
      interactivity: {
        detect_on: "canvas",
        events: {
          onHover: {
            enable: false,
            mode: "bubble",
            parallax: {
              enable: false,
              force: 60,
              smooth: 10
            }
          },
          onClick: {
            enable: false,
            mode: "push"
          },
          resize: true
        },
        modes: {
          grab: {
            distance: 450,
            lineLinked: {
              opacity: 1
            }
          },
          bubble: {
            distance: 450,
            size: 100,
            duration: 3,
            opacity: 1,
            speed: .2
          },
          repulse: {
            distance: 200
          },
          push: {
            particles_nb: 4
          },
          remove: {
            particles_nb: 2
          }
        }
      },
      backgroundMask: {
        enable: true,
        cover: {
          color: {
            value: {
              r: 20,
              g: 20,
              b: 20
            }
          }
        }
      },
      retina_detect: true,
      fps_limit: 60,
      background: {
        image: "url('https://particles.js.org/images/background3.jpg')"
      }
    }
    this.__resolver = new ComponentImportHandler().FullLoader('150')
    this.handleLoading = this.handleLoading.bind(this);
  }
  handleLoading = (e) => {

    this.setState({ loading: false }) 

  }


  async componentDidMount(){

    document.querySelectorAll('html')[0].setAttribute('lang', 'de')
    document.querySelectorAll('title')[0].textContent='Websites und Apps erstellen lassen | Calypso Software'
    

  
    this.sec = new Security()
    this.sec.formControl();
    this.CookieHandler = new CookieHandler();
    this.CookieHandler.createSessionCookie();


  }

  render(){  
    return(
      <>
      {              
        <>
          { this.state.loading ? this.__resolver : undefined }       
          { <NavigationComponent /> }      
          {  new ComponentImportHandler().Logo()   }
          <div className="Landing-Pages-Container" >

          <div className="Content-Box">
            <div className="Content__Column First__">
              <div className="Content__Column_Svg">
                  {!this.state.loading ? <SlidingWords /> : undefined}
              </div>
            </div>
            <br/>

            <div className="Content__Column" >
              <Column app_column={true} />  {/*Landing Header */}
            </div>
            <div className="Content__Column" _arialabel="Webseite ertsellen lassen">
              <Column app_column_1={true} /> {/*Webseite erstellen lassen*/}
            </div>
            <div className="Content__Column">
              <Column app_column_3={true} _arialabel="Werbung via Social-Media"/> {/*Social Media Werbung*/}
            </div>
            <div className="Content__Column">
              <Column app_column_2={true} _arialabel="Wie viel kostet eine Webseite"/> {/*was kostet eine Webseite */}
            </div>
            <div className="Content__Column">
              <Column app_column_4={true} _arialabel="Hosting und Domain-Checker"/>  {/*Domain und Server */}
            </div>
            <div className="Content__Column">
              <Column app_column_5={true} _arialabel="SEO Optimierung"/>  {/*SEO Optimierung */}
            </div>
            <div className="Content__Column">
              <Column app_column_6={true} _arialabel="Responsive Design"/>  {/*Responsive Design */}
            </div>
            <div className="Content__Column">
              <Column app_column_7={true} _arialabel="Technologien der Webentwicklung"/>  {/*Technologien */}
            </div>
            <div className="Content__Column">
              <Column app_column_8={true} _arialabel="Kontaktformular"/>  {/*Kontakt-Formular */}
            </div>            
            <div className="Content__Column">
              <Column app_column_9={true} _arialabel="Echtzeit Nachrichten aus aller Welt" />
            </div>            
          </div>
          
          <div className="Content-Background">
           {/* <TsParticles location={"app_main"} options={this.options} particlesLoaded={this.handleLoading}/> */}
            { new ComponentImportHandler().Waves() }
          </div>      
          </div>
        
        <MenuComp/>       
        <Footer />
        
      
      </> 
       
      }
        
      </>
    );
  }
}
class SlidingWords extends Component {

  render() {    
    return (
      <div className="words-container" >
        <div className="word">JavaScript</div>
        <div className="word">NODE.JS</div>
        <div className="word">REACT.JS</div>

      </div>
    )
  }
}

// KJHpiub <WelcomeElement/>