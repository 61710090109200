import React from 'react';
import './CSS/App.css';
import './CSS/index.css';

import ScrollEvent from '../../Middleware/ScrollEvent'
import MenuComp from '../MenuComp/MenuComp';
// import HeaderComp from './HeaderComp';
import FullScreenLoader from '../LoadingScreen/fullscreen/FullScreenLoader';
import MobContentContainer from './mobile/ContentContainer'
import NavigationComponent from './comp/NavMenu';
import Footer from '../Footer/Footer';
import ComponentImportHandler from '../import/ImportHandler';

class Webworker {
  async getContent(){
      return await fetch('content.json',{method: 'GET'})
      .then(response => response.json())
      .then(response => response)
      .catch( async (err) =>{
          console.error(err);
      })
  }
}

export default class SkillMaintain extends React.Component {
  constructor(props) { 
    super(props); 
    window.scrollTo(0, 0)
    this.state = {loading: true}
    this.handleTrigger = this.handleTrigger.bind(this)
    document.querySelectorAll('html')[0].setAttribute('lang', 'de')
    document.querySelectorAll('title')[0].textContent='Häufig gestellte Fragen | Calypso Software'


  }
  handleTrigger() {   
    // console.log('deer loaded.. ')
  }
  async componentDidMount(){
    
    this.content = await new Webworker().getContent()
    this.setState({ loading: false })
    new ScrollEvent({section: 'content-container_1'})

  }  

// <= 767 ? false : true 
  render(){  
     
      return (     
        <div id="container-FAQ-hydrate">    
        <div className="faq-main-container"> 
        <NavigationComponent />         
        { new ComponentImportHandler().Logo() }
          <div className="App" hidden={ this.state.loading }  >       
          { new ComponentImportHandler().Waves() }
                             
          </div>
          <div className="welcomeCon _default ">        
              {/* !this.state.loading ? <h2 style={{marginTop: '200px',fontSize: window.innerWidth >  786 ? '3rem' : '2rem'}}>. .. ..About.. .. .</h2> : null */}
              <h4 style={{opacity: '0.2',marginTop: '200px',fontSize: window.innerWidth >  786 ? '3rem' : '2rem'}}>Example Page</h4>
          </div> 

        { !this.state.loading ? 
          <>
            <MobContentContainer content={ this.content }/>            
            
            <MenuComp />    
            
          </> 
          : 
          <FullScreenLoader /> 
        }
        
        </div>
        
        { !this.state.loading ? <Footer /> : <></>} 
        { !this.state.loading ? <></> : <></> }
        </div>
      );
    
  }
}   